import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToolBar from './ToolBar';
import HeroTeacher from './HeroTeacher';
import * as images from '../../assets/images'; // Ensure this path is correct

const useStyles = makeStyles((theme) => ({
  splashScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#23b5b5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.zIndex.modal,
    color: 'white',
    flexDirection: 'column',
    textAlign: 'center',
    fontFamily: 'Poppins',
  },
  splashImage: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(2),
  },
}));

const Landing = () => {
  const classes = useStyles();
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    // Show splash screen for 2 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 1500); // Adjust time as needed

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {/* Splash Screen */}
      {showSplash && (
        <div className={classes.splashScreen}>
          <img src={images.teacher} alt="Splash" className={classes.splashImage} />
          <Typography variant="h4">Welcome!</Typography>
          <Typography variant="h6">We are preparing something amazing for you.</Typography>
          <CircularProgress color="secondary" />
        </div>
      )}

      {/* Main Content */}
      {!showSplash && (
        <>
          <HeroTeacher />
        </>
      )}
    </div>
  );
};

export default Landing;
