import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import {
  ai,
  games,
  lessons,
  course,
  flashcards,
  splitScreen,
} from "../../assets/images/index";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: "#000", // Dark theme background
  },
  headingContainer: {
    textAlign: "left",
    marginBottom: theme.spacing(4),
  },
  heading: {
    textAlign: "center",
    color: "#fff",
    fontWeight: 700,
    fontSize: "3rem",
    fontFamily: "Poppins, sans-serif",
  },
  subheading: {
    textAlign: "center",
    color: "#BDC4CE",
    fontSize: "1.1rem",
    fontFamily: "Poppins, sans-serif",
    marginTop: theme.spacing(2),
  },
  featuresContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  featureItem: {
    flex: "0 0 18%", // Adjust for 5 items in a row
    marginBottom: theme.spacing(4),
    textAlign: "left",
  },
  image: {
    width: "100%",
    height: "auto",
    maxWidth: 200, // Set a max-width for the images
  },
  title: {
    fontWeight: 700,
    color: "#fff",
    fontFamily: "Poppins, sans-serif",
    marginTop: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(1),
    color: "#BDC4CE",
    fontFamily: "Poppins, sans-serif",
    width: "100%",
  },
}));

const features = [
  {
    title: "AI Assistants",
    description:
      "Instant feedback from AI for conversations and pronunciation.",
    image: ai,
  },
  {
    title: "Games",
    description: "Interactive games to reinforce vocabulary and grammar.",
    image: games,
  },
  {
    title: "Live Lessons",
    description: "Real-time lessons with teachers to improve speaking skills.",
    image: lessons,
  },
  {
    title: "Courses",
    description: "Step-by-step courses to master language proficiency.",
    image: course,
  },
  {
    title: "Flashcards",
    description: "Customizable flashcards to memorize vocabulary.",
    image: flashcards,
  },
  // {
  //   title: "Split Screen",
  //   description: "Study two languages side by side for easy comparison.",
  //   image: splitScreen,
  // },
];

const Features = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.headingContainer}>
        <Typography variant="h1" className={classes.heading}>
          Features
        </Typography>
        <Typography variant="subtitle1" className={classes.subheading}>
          Explified offers a variety of tools and features to help you learn a new language.
        </Typography>
      </Box>
      <Box className={classes.featuresContainer}>
        {features.map((feature, index) => (
          <Box key={index} className={classes.featureItem}>
            <img
              src={feature.image}
              alt={feature.title}
              className={classes.image}
            />
            <Typography variant="h6" className={classes.title}>
              {feature.title}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {feature.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Features;
