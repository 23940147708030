import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    Divider,
    Chip
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        backgroundColor: '#121212',  // Dark background for the page
    },
    blog: {
        backgroundColor: '#1e1e1e',  // Darker background for blog detail card
        color: '#e0e0e0',
        borderRadius: 12,
        boxShadow: '0 4px 20px rgba(255, 255, 255, 0.1)',
    },
    blogContent: {
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 600,
        color: '#ffffff',  // Main title text color
        fontFamily: 'Poppins, sans-serif',
    },
    author: {
        color: '#bdbdbd',  // Light gray for author and date
        fontSize: '0.875rem',
        fontFamily: 'Poppins, sans-serif',
    },
    date: {
        color: '#bdbdbd',
        fontSize: '0.875rem',
        marginBottom: theme.spacing(2),
        fontFamily: 'Poppins, sans-serif',
    },
    body: {
        lineHeight: 1.6,
        color: '#e0e0e0',
        fontFamily: 'Poppins, sans-serif',
        '& img': {
            maxWidth: '100%',
            height: 'auto',
            display: 'block',
            marginBottom: theme.spacing(2),
        },
    },
    relatedBlogs: {
        backgroundColor: '#1e1e1e',  // Darker background for related blogs card
        color: '#e0e0e0',
        borderRadius: 12,
        boxShadow: '0 4px 20px rgba(255, 255, 255, 0.1)',
    },
    relatedTitle: {
        fontWeight: 600,
        color: '#ffffff',
        fontFamily: 'Poppins, sans-serif',
        marginBottom: theme.spacing(2),
    },
    relatedBlogsList: {
        padding: 0,
    },
    relatedBlogItem: {
        padding: theme.spacing(1, 2),
        color: '#e0e0e0',
        '&:hover': {
            backgroundColor: '#2c2c2c',  // Darker shade on hover
            cursor: 'pointer',
        },
    },
    loader: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.main,
    },
    tags: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    tagChip: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: '#2c2c2c',
        color: '#e0e0e0',
        '&:hover': {
            backgroundColor: '#3c3c3c',
            cursor: 'pointer',
        },
    },
}));

const BlogDetail = () => {
    const classes = useStyles();
    const { permalink } = useParams();
    const history = useHistory();
    const [post, setPost] = useState(null);
    const [relatedPosts, setRelatedPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchPost = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`https://backend-explified.web.app/api/v1/cms/posts/permalink/${permalink}`);
                setPost(response.data);

                const relatedPostsResponse = await axios.get('https://backend-explified.web.app/api/v1/cms/posts/main-posts');
                const allPosts = relatedPostsResponse.data.posts;
                const filteredPosts = allPosts.filter((p) => p.permalink !== permalink);
                setRelatedPosts(filteredPosts.slice(0, 5));
            } catch (error) {
                setError(true);
                console.error('Error fetching post:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [permalink]);

    const handleTagClick = (tag) => {
        history.push(`/blogs?tag=${tag}`);
    };

    const handleCategoryClick = (category) => {
        history.push(`/blogs?category=${category}`);
    };

    if (loading) {
        return (
            <div className={classes.loader}>
                <CircularProgress />
            </div>
        );
    }

    if (error || !post) {
        return (
            <div className={classes.loader}>
                <Typography variant="h6" color="error">
                    Failed to load post. Please try again later.
                </Typography>
            </div>
        );
    }

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Card className={classes.blog}>
                        <CardContent className={classes.blogContent}>
                            <Typography variant="h3" component="h1" className={classes.title}>
                                {post.title}
                            </Typography>
                            <Typography className={classes.author}>
                                {post.author}
                            </Typography>
                            <Typography className={classes.date}>
                                {new Date(post.createdAt._seconds * 1000).toLocaleDateString()}
                            </Typography>
                            <div className={classes.body} dangerouslySetInnerHTML={{ __html: post.content }} />

                            {/* Category */}
                            <div className={classes.tags}>
                                {post.categories?.map((category) => (
                                    <Chip
                                        key={category}
                                        label={category}
                                        className={classes.tagChip}
                                        onClick={() => handleCategoryClick(category)}
                                    />
                                ))}
                            </div>

                            {/* Tag */}
                            <div className={classes.tags}>
                                {post.tags.map((tag) => (
                                    <Chip
                                        key={tag}
                                        label={`#${tag}`}
                                        className={classes.tagChip}
                                        onClick={() => handleTagClick(tag)}
                                    />
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className={classes.relatedBlogs}>
                        <CardContent>
                            <Typography variant="h5" component="h2" className={classes.relatedTitle}>
                                Related Blogs
                            </Typography>
                            <List className={classes.relatedBlogsList}>
                                {relatedPosts.map((relatedPost) => (
                                    <React.Fragment key={relatedPost.id}>
                                        <ListItem button component={Link} to={`/blog/${relatedPost.permalink}`} className={classes.relatedBlogItem}>
                                            <ListItemText
                                                primary={relatedPost.title}
                                                secondary={
                                                    <>
                                                        <Typography variant="body2" component="span" className={classes.author}>
                                                            {relatedPost.author}
                                                        </Typography>
                                                        <br />
                                                        <Typography variant="body2" component="span" className={classes.date}>
                                                            {new Date(relatedPost.createdAt.seconds * 1000).toLocaleDateString()}
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BlogDetail;
