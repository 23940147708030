import React from "react";
import { Grid, Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  factorCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 20px",
    margin: "10px 0",
    color: "#BDC4CE", // Text color
    backgroundColor: "#1A222C", // Secondary background color
    border: "1px solid #24303F", // Main background color
    fontFamily: "Poppins, sans-serif",
    transition: "background-color 0.3s, border 0.3s",
    "&:hover": {
      backgroundColor: "#24303F", // Main background color on hover
      border: "1px solid #BDC4CE", // Text color on hover
      cursor: "pointer",
    },
  },
  score: {
    width: "34px",
    height: "28px",
    borderRadius: "25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    color: "#000", // Black text color for better contrast
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "20px",
    marginBottom: "20px",
    backgroundColor: "#000000f5",
  },
}));

const FactorScore = ({ factor, score, color }) => {
  const classes = useStyles();

  return (
    <Card className={classes.factorCard}>
      <Typography variant="body1" style={{ fontFamily: "Poppins, sans-serif" }}>
        {factor}
      </Typography>
      <div className={classes.score} style={{ backgroundColor: color }}>
        <Typography style={{ fontFamily: "Poppins, sans-serif" }}>
          {score}
        </Typography>
      </div>
    </Card>
  );
};

const FactorRow = ({ skills }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.gridContainer}>
      {skills.map((skillSet, index) =>
        skillSet.map((item, idx) => (
          <Grid item key={`${index}-${idx}`}>
            <FactorScore factor={item.name} score={item.score} color="#4caf50" />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default FactorRow;
