// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Button,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Hidden,
//   Divider,
//   // Grid,
//   makeStyles,
//   // useMediaQuery,
//   useTheme,
// } from "@material-ui/core";
// import { FaBars, FaTimes } from "react-icons/fa";
// // import LoginModal from "../home/LoginModal";
// import LoginPopup from "../home/LoginPopup";
// import axios from "axios";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     // backgroundColor: "#2563eb",
//     backgroundColor: "#000000",
//     // backgroundColor: "#161616",
//     // backgroundColor:"#0B0C14",
//     paddingInline : "125px",
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   customButton: {
//     fontSize: "1rem",
//     fontFamily: "Poppins, sans-serif",
//     backgroundColor:"white",
//     color: "black",
//     margin: theme.spacing(2),
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//     borderRadius: "16px",
//     // textTransform: "none",
//     "&:hover": {
//       backgroundColor: "rgba(255, 255, 255, 0.1)",
//     },
//     [theme.breakpoints.up("sm")]: {
//       fontSize: "0.75rem",
//       margin: theme.spacing(2, 1),
//     },
//     [theme.breakpoints.up("md")]: {
//       fontSize: "1rem",
//       margin: theme.spacing(2),
//     },
//   },
//   title: {
//     flexGrow: 1,
//     textDecoration: "none",
//     color: "white",
//     "&:hover": {
//       color: "rgb(223, 211, 211)",
//       cursor: "pointer",
//     },
//     fontWeight: "bold",
//     fontFamily: "Poppins",
//     fontSize: "1.5rem",
//   },
//   drawer: {
//     width: 250,
//   },
//   drawerPaper: {
//     width: 250,
//   },
//   link: {
//     textDecoration: "none",
//     color: "inherit",
//     fontFamily: "Poppins, sans-serif",
//   },
//   dropdown: {
//     position: "absolute",
//     top: "40%",
//     left: 0,
//     zIndex: 1,
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     width: "100%",
//     padding: theme.spacing(2),
//     display: "flex",
//     flexDirection: "column",
//     color: theme.palette.text.primary,
//     fontFamily: "Poppins, sans-serif",
//     [theme.breakpoints.up("sm")]: {
//       left: 20,
//       top: "100%",
//       flexDirection: "row",
//       boxShadow: theme.shadows[8],
//       width: "95%",
//     },
//     [theme.breakpoints.up("md")]: {
//       left: "80",
//     },
//   },
//   dropdownColumn: {
//     flex: 1,
//     padding: theme.spacing(1),
//     [theme.breakpoints.up("md")]: {
//       borderRight: `1px solid ${theme.palette.divider}`,
//     },
//     "&:last-child": {
//       [theme.breakpoints.up("md")]: {
//         borderRight: "none",
//       },
//     },
//     fontFamily: "Poppins, sans-serif",
//   },
//   dropdownItem: {
//     padding: theme.spacing(1),
//     color: theme.palette.text.primary,
//     fontFamily: "Poppins, sans-serif",
//   },
//   dropdownCategory: {
//     fontWeight: "bold",
//     textTransform: "uppercase",
//     fontFamily: "Poppins, sans-serif",
//   },
// }));

// const Navbar = () => {
//   const classes = useStyles();
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const [open, setOpen] = useState(false);
//   const history = useHistory();

//   const token = localStorage.getItem("authtoken");

//   const handleOpen = () => {
//     setOpen(true);
//   }
//   const handleClose = () =>{
//     setOpen(false);
//   }

//   const handleLogout = async () => {
//     try{
//       await axios.post("https://backend-explified.web.app/api/v1/auth/logout");
//       localStorage.removeItem("authtoken");
//       history.push("/");
//     }
//     catch(error){
//       console.error("Logout Failed",error);
//     }
//   }

//   // const theme = useTheme();
//   // const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

//   // const toggleDropdown = () => {
//   //   setIsDropdownOpen(!isDropdownOpen);
//   // };

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   // const closeDropdown = () => {
//   //   setIsDropdownOpen(false);
//   // };

//   const closeMobileMenu = () => {
//     setIsMobileMenuOpen(false);
//   };

//   return (
//     <div className={classes.root}>
//       <AppBar position="absolute" className={classes.root}>
//         <Toolbar>
//           <Typography
//             variant="h4"
//             className={classes.title}
//             component={Link}
//             to="/"
//           >
//             Explified
//           </Typography>
//           <p style={{ color: "white"}}>We have not yet gone live. Still under Development.</p>
//           <Hidden smUp>
//             <IconButton
//               edge="start"
//               className={classes.menuButton}
//               color="inherit"
//               aria-label="menu"
//               onClick={toggleMobileMenu}
//             >
//               {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
//             </IconButton>
//           </Hidden>
//           <Hidden xsDown>
//              <Button
//               color="contained"
//               variant="outlined"
//               onClick={token ? handleLogout : handleOpen}
//               className={classes.customButton}
//             >
//               {
//                 token ? "Logout" : "Login"
//               }
//             </Button>
//             <LoginPopup
//               open={open}
//               onClose={handleClose}
//             />
//           </Hidden>
//         </Toolbar>
//       </AppBar>

//       {/* Mobile menu */}
//       <Hidden smUp>
//         <Drawer
//           open={isMobileMenuOpen}
//           onClose={closeMobileMenu}
//           classes={{ paper: classes.drawerPaper }}
//         >
//           <List>
//             <ListItem button onClick={closeMobileMenu}>
//               <ListItemText primary="Close Menu" />
//               <IconButton edge="end" color="inherit">
//                 <FaTimes />
//               </IconButton>
//             </ListItem>
//             <Divider />
//             <Divider />
//             <ListItem button component={Link} to="/">
//               <ListItemText primary="Playground" />
//             </ListItem>
           
//             <ListItem button component={Link} to="/dashboard">
//               <ListItemText primary="Login" />
//             </ListItem>
//           </List>
//         </Drawer>
//       </Hidden>
//     </div>
//   );
// };

// export default Navbar;


//
//
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { FaBars, FaTimes } from "react-icons/fa";
import LoginPopup from "../home/LoginPopup";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#000000",
    paddingInline: "125px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  customButton: {
    fontSize: "1rem",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "white",
    color: "black",
    margin: theme.spacing(2),
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "16px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
    color: "white",
    "&:hover": {
      color: "rgb(223, 211, 211)",
      cursor: "pointer",
    },
    fontWeight: "bold",
    fontFamily: "Poppins",
    fontSize: "1.5rem",
  },
  drawerPaper: {
    width: 250,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontFamily: "Poppins, sans-serif",
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const token = localStorage.getItem("authtoken");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = async () => {
    try {
      await axios.post("https://backend-explified.web.app/api/v1/auth/logout");
      localStorage.removeItem("authtoken");
      history.push("/");
    } catch (error) {
      console.error("Logout Failed", error);
    }
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={classes.root}>
        <Toolbar>
          <Typography
            variant="h4"
            className={classes.title}
            component={Link}
            to="/"
          >
            Explified
          </Typography>
          <Typography variant="body2" style={{ color: "white", marginRight: "auto" }}>
            We have not yet gone live. Still under Development.
          </Typography>
          <Hidden xsDown>
            <Button
              component={Link}
              to="/be-a-tutor"
              color="inherit"
              className={classes.customButton}
            >
              Be a Tutor
            </Button>
            <Button
              component={Link}
              to="/blogs"
              color="inherit"
              className={classes.customButton}
            >
              Blogs
            </Button>
            <Button
              color="contained"
              variant="outlined"
              onClick={token ? handleLogout : handleOpen}
              className={classes.customButton}
            >
              {token ? "Logout" : "Login"}
            </Button>
            <LoginPopup open={open} onClose={handleClose} />
          </Hidden>
          <Hidden smUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>

      {/* Mobile menu */}
      <Hidden smUp>
        <Drawer
          open={isMobileMenuOpen}
          onClose={closeMobileMenu}
          classes={{ paper: classes.drawerPaper }}
        >
          <List>
            <ListItem button onClick={closeMobileMenu}>
              <ListItemText primary="Close Menu" />
              <IconButton edge="end" color="inherit">
                <FaTimes />
              </IconButton>
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/" onClick={closeMobileMenu}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/blogs" onClick={closeMobileMenu}>
              <ListItemText primary="Blogs" />
            </ListItem>
            <ListItem button onClick={token ? handleLogout : handleOpen}>
              <ListItemText primary={token ? "Logout" : "Login"} />
            </ListItem>
          </List>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Navbar;

