import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  parallaxSection: {
    height: "500px",
    // background: "linear-gradient(0deg, #568BFF, #2563eb)",
    // backgroundColor: "#000",
    // backgroundColor: "0B0C14",
    // opacity: "1",
    // backgroundImage: `repeating-radial-gradient(circle at 0 0, transparent 0, #000 10px),
    //                 repeating-linear-gradient(#0f0f0f55, #0f0f0f)`,
    //   backgroundImage: `
    //   radial-gradient(circle at 16% 83%, rgba(148, 148, 148, 0.06) 0%, rgba(148, 148, 148, 0.06) 50%, rgba(63, 63, 63, 0.06) 50%, rgba(63, 63, 63, 0.06) 100%),
    //   radial-gradient(circle at 68% 87%, rgba(66, 66, 66, 0.06) 0%, rgba(66, 66, 66, 0.06) 50%, rgba(105, 105, 105, 0.06) 50%, rgba(105, 105, 105, 0.06) 100%),
    //   radial-gradient(circle at 38% 50%, rgba(123, 123, 123, 0.06) 0%, rgba(123, 123, 123, 0.06) 50%, rgba(172, 172, 172, 0.06) 50%, rgba(172, 172, 172, 0.06) 100%),
    //   linear-gradient(90deg, hsl(18, 0%, 1%), hsl(18, 0%, 1%))
    // `,
    // backgroundImage: `
    //   radial-gradient(circle at 16% 83%, rgba(148, 148, 148, 0.06) 0%, rgba(148, 148, 148, 0.06) 50%, rgba(63, 63, 63, 0.06) 50%, rgba(63, 63, 63, 0.06) 100%),
    //   radial-gradient(circle at 68% 87%, rgba(66, 66, 66, 0.06) 0%, rgba(66, 66, 66, 0.06) 50%, rgba(105, 105, 105, 0.06) 50%, rgba(105, 105, 105, 0.06) 100%),
    //   radial-gradient(circle at 38% 50%, rgba(123, 123, 123, 0.06) 0%, rgba(123, 123, 123, 0.06) 50%, rgba(172, 172, 172, 0.06) 50%, rgba(172, 172, 172, 0.06) 100%),
    //   linear-gradient(90deg, rgb(11, 12, 20), rgb(11, 12, 20))
    // `,
    //   backgroundImage: `
    //   radial-gradient(circle at 16% 83%, rgba(148, 148, 148, 0.06) 0%, rgba(148, 148, 148, 0.06) 50%, rgba(63, 63, 63, 0.06) 50%, rgba(63, 63, 63, 0.06) 100%),
    //   radial-gradient(circle at 68% 87%, rgba(66, 66, 66, 0.06) 0%, rgba(66, 66, 66, 0.06) 50%, rgba(105, 105, 105, 0.06) 50%, rgba(105, 105, 105, 0.06) 100%),
    //   radial-gradient(circle at 38% 50%, rgba(123, 123, 123, 0.06) 0%, rgba(123, 123, 123, 0.06) 50%, rgba(172, 172, 172, 0.06) 50%, rgba(172, 172, 172, 0.06) 100%),
    //   linear-gradient(90deg, rgb(0, 0, 0), rgb(0, 0, 0))
    // `,
    //   backgroundImage: `
    //   linear-gradient(67.5deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%),
    //   linear-gradient(90deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%),
    //   linear-gradient(135deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%),
    //   linear-gradient(0deg, rgb(6, 6, 6) 0%, rgb(6, 6, 6) 6%, rgb(29, 29, 29) 6%, rgb(29, 29, 29) 57%, rgb(52, 52, 52) 57%, rgb(52, 52, 52) 58%, rgb(75, 75, 75) 58%, rgb(75, 75, 75) 79%, rgb(97, 97, 97) 79%, rgb(97, 97, 97) 93%, rgb(120, 120, 120) 93%, rgb(120, 120, 120) 95%, rgb(143, 143, 143) 95%, rgb(143, 143, 143) 100%),
    //   linear-gradient(90deg, rgb(8, 8, 8), rgb(221, 221, 221))
    // `,
    // backgroundBlendMode: 'overlay, overlay, overlay, overlay, normal',

    backgroundImage: `
        radial-gradient(circle at 99% 33%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 37%, transparent 37%, transparent 100%),
        radial-gradient(circle at 46% 16%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 43%, transparent 43%, transparent 100%),
        radial-gradient(circle at 99% 25%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 22%, transparent 22%, transparent 100%),
        radial-gradient(circle at 57% 88%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 86%, transparent 86%, transparent 100%),
        radial-gradient(circle at 82% 78%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 22%, transparent 22%, transparent 100%),
        linear-gradient(90deg, rgb(0, 0, 0), rgb(0, 0, 0))
      `,

    //   backgroundImage: `
    //   radial-gradient(circle at 99% 33%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 37%, transparent 37%, transparent 100%),
    //   radial-gradient(circle at 46% 16%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 43%, transparent 43%, transparent 100%),
    //   radial-gradient(circle at 99% 25%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 22%, transparent 22%, transparent 100%),
    //   radial-gradient(circle at 57% 88%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 86%, transparent 86%, transparent 100%),
    //   radial-gradient(circle at 82% 78%, hsla(235, 0%, 48%, 0.03) 0%, hsla(235, 0%, 48%, 0.03) 22%, transparent 22%, transparent 100%),
    //   linear-gradient(90deg, rgb(11, 12, 20), rgb(11, 12, 20))
    // `,

    //   backgroundImage: `
    //   repeating-linear-gradient(0deg, rgb(17, 17, 17) 0px, rgb(17, 17, 17) 1px, transparent 1px, transparent 51px),
    //   repeating-linear-gradient(90deg, rgb(17, 17, 17) 0px, rgb(17, 17, 17) 1px, transparent 1px, transparent 51px),
    //   linear-gradient(90deg, rgb(11, 12, 20), rgb(11, 12, 20))
    // `,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Poppins, sans-serif",
  },
  overlay: {
    // backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: theme.spacing(4),
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
  },
  courseHeading: {
    color: "#fff",
    marginBottom: theme.spacing(3),
    fontWeight: "bold",
    fontFamily: "Poppins, sans-serif",
  },
  courseButtons: {
    fontFamily: "Poppins, sans-serif",
    "& > *": {
      margin: theme.spacing(1),
      color: theme.palette.primary.contrastText,
    },
  },
  course: {
    fontSize: "1.5rem",
    fontFamily: "Poppins, sans-serif",
    color: "#fff",
    fontWeight: "bold",
  },
}));

const ParallaxSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.parallaxSection}>
      <Container className={classes.overlay} maxWidth="md">
        <Typography
          variant="h3"
          className={classes.courseHeading}
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Explore Our Courses
        </Typography>
        <div className={classes.courseButtons}>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>GRE</Typography>
          </Button>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>GMAT</Typography>
          </Button>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>TOEFL</Typography>
          </Button>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>SAT</Typography>
          </Button>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>IELTS</Typography>
          </Button>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>LSAT</Typography>
          </Button>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>MCAT</Typography>
          </Button>
          <Button variant="outlined" size="large" color="secondary">
            <Typography className={classes.course}>CET</Typography>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ParallaxSection;
