import React, {useEffect} from 'react'

const Disclaimer = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div style={{ 
            minHeight:"100vh", 
            marginTop:"40px",
            backgroundColor: "#0B0C14", // main background
            color: "#BDC4CE", // neutral text color
            minHeight: "90vh", // Ensure it takes full height of the screen
            padding: "50px" }}>
            <h1>EXPLIFIED</h1>
            <br />
            <h2>DISCLAIMER</h2>

            <section>
                <br />
                <p>
                    GMAT® is a registered trademark of the Graduate Management Admission Council (GMAC). This website is not endorsed or approved by GMAC. GRE®, TOEFL®, and Praxis® are registered trademarks of Educational Testing Service (ETS). This website is not endorsed or approved by ETS. SAT® is a registered trademark of the College Board, which was not involved in the production of, and does not endorse this product. LSAT® is a registered trademark of the Law School Admission Council, Inc. This website is not endorsed or approved by the LSAC. ACT® is a registered trademark of ACT, inc. This website is not endorsed or approved by ACT, inc. MCAT is a registered trademark of the Association of American Medical Colleges (AAMC). This website is not endorsed or approved by AAMC.
                </p>
            </section>
        </div>
    )
}

export default Disclaimer