import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  makeStyles,
  Snackbar,
  IconButton,
  Checkbox,
  FormControlLabel,
  Typography,
  Toolbar,
  AppBar,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FaGoogle, FaApple, FaFacebook } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    fontFamily: "Poppins, sans-serif",
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "#23b5b5",
    color: "#fff",
    fontWeight: "bold",
    padding: theme.spacing(1.5, 3),
    borderRadius: "4px",
    boxShadow: theme.shadows[2],
    "&:hover": {
      backgroundColor: "#1f9fa3",
    },
  },
  dialogTitle: {
    fontFamily: "Poppins, sans-serif",
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#23b5b5",
    padding: theme.spacing(2, 0),
  },
  closeButton: {
    color: theme.palette.secondary.main,
    fontFamily: "Poppins, sans-serif",
    alignSelf: "center",
  },
  textField: {
    fontFamily: "Poppins, sans-serif",
    padding: theme.spacing(1, 0),
    "& .MuiInputBase-root": {
      height: "100%",
      fontFamily: "Poppins",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Poppins",
    },
  },
  toolbar: {
    backgroundColor: "#23b5b5",
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    fontFamily: "Poppins, sans-serif",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  snackbar: {
    fontFamily: "Poppins, sans-serif",
  },
  divider: {
    margin: theme.spacing(2, 0),
    fontFamily: "Poppins, sans-serif",
  },
  iconButtons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  link: {
    color: "#23b5b5",
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "Poppins, sans-serif",
  },
}));

function LoginModal({ isOpen, onClose }) {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();

    // Basic validation (for demonstration purposes)
    if (email === "" || password === "") {
      setSnackbarMessage("Please enter both email and password.");
      setSnackbarOpen(true);
      return;
    }

    // Example of hardcoded credentials for validation
    if (email === "tech@explified.com" && password === "password123") {
      setSnackbarMessage("Login successful!");
      setSnackbarOpen(true);
      setTimeout(() => {
        history.push("/dashboard"); // Navigate to dashboard
      }, 2000);
    } else {
      setSnackbarMessage("Invalid email or password.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { minHeight: "100vh", maxHeight: "100vh" } }}
    >
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.logo}>Explified</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogTitle className={classes.dialogTitle}>
        Sign into your Explified Account
      </DialogTitle>
      <DialogContent>
        <Typography align="center" gutterBottom>
          If you don't have an account,{" "}
          <span className={classes.link}>please create one</span>.
        </Typography>
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            fullWidth
            className={classes.textField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            className={classes.textField}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Remember me"
            className={classes.textField}
          />
          <Typography
            variant="body2"
            className={classes.link}
            align="right"
            onClick={() => alert("Forgot password clicked")}
          >
            Forgot password?
          </Typography>
          <Button
            type="submit"
            variant="contained"
            className={classes.button}
          >
            Login
          </Button>
        </form>
        <Divider className={classes.divider} variant="middle">
        
        </Divider>
        <div className={classes.iconButtons}>
          <IconButton onClick={() => alert("Google Sign-In clicked")}>
            <FaGoogle style={{ color: "blue" }} />
          </IconButton>
          <IconButton onClick={() => alert("Facebook Sign-In clicked")}>
            <FaFacebook style={{ color: "#4267B2" }} />
          </IconButton>
          <IconButton onClick={() => alert("Apple Sign-In clicked")}>
            <FaApple style={{ color: "#000000" }} />
          </IconButton>
        </div>
      </DialogContent>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        ContentProps={{
          className: classes.snackbar,
          style: { backgroundColor: "green" },
        }}
        action={
          <>
            <IconButton
              size="small"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Dialog>
  );
}

export default LoginModal;
