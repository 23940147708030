import React, { useState } from 'react';
import { Grid, Typography, Button, makeStyles, Dialog, DialogContent, IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import * as images from '../../assets/images';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GetStarted from './GetStarted';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f7f7f7',
  },
  leftSection: {
    paddingRight: theme.spacing(4),
  },
  tagline: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#333',
    fontFamily:'Poppins',
    marginLeft:"24px",
  },
  journeyContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    fontFamily:'Poppins',
  },
  journeyStep: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `0 ${theme.spacing(2)}px`,
    fontFamily:'Poppins',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
  },
  stepTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: '#23b5b5',
    fontFamily:'Poppins',
  },
  stepDescription: {
    fontSize: '0.9rem',
    textAlign: 'center',
    color: '#555',
    fontFamily:'Poppins',
  },
  getStartedButton: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 4),
    color: 'white',
    marginLeft:"24px",
    fontFamily:'Poppins',
    backgroundColor: '#23b5b5',
    '&:hover': {
      backgroundColor: '#1e9d9d',
    },
  },
  rightSection: {
    textAlign: 'center',
  },
  heroImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.spacing(2),
  },
  dialogContent: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const HeroTeacher = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnclick = () => {
    setOpen(false); // Close the dialog
    history.push('/teacher-dashboard');
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={6} className={classes.leftSection}>
          <Typography className={classes.tagline}>
            Make a living by teaching
          </Typography>
          <div className={classes.journeyContainer}>
            <div className={classes.journeyStep}>
              <Typography className={classes.stepTitle}>Sign Up</Typography>
              <Typography className={classes.stepDescription}>
                Create your account to get started.
              </Typography>
            </div>
            <div className={classes.journeyStep}>
              <Typography className={classes.stepTitle}>Get Approved</Typography>
              <Typography className={classes.stepDescription}>
                Pass the verification process.
              </Typography>
            </div>
            <div className={classes.journeyStep}>
              <Typography className={classes.stepTitle}>Get Paid</Typography>
              <Typography className={classes.stepDescription}>
                Start earning by teaching.
              </Typography>
            </div>
          </div>
          <Button
            variant="contained"
            className={classes.getStartedButton}
            endIcon={<ArrowForwardIcon />}
            onClick={handleOpen}
          >
            Get Started
          </Button>
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightSection}>
          <img
            src={images.teacher}
            alt="Hero"
            className={classes.heroImage}
          />
        </Grid>
      </Grid>

      {/* GetStarted Dialog */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <DialogContent className={classes.dialogContent}>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <GetStarted onComplete={handleOnclick} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HeroTeacher;
