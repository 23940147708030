import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container, 
  Grid, 
} from "@material-ui/core";
import ChatBot from "./ChatBot";

import * as images from "../../assets/images";
import Carousel from "react-material-ui-carousel";
import LanguageTestAnalysis from "./analysis/LanguageTaskAnalysis";
import FactorScore from "./analysis/FactorScore";

const useStyles = makeStyles((theme) => ({
  chatBotFullScreen: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2000,
    backgroundColor: theme.palette.background.paper,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    padding: theme.spacing(4),
  },
 
}));

const Widgets = ({ done, setDone }) => {
  const classes = useStyles();
  const [isChatBotOnly, setIsChatBotOnly] = useState(false);
  const [languageProgress, setLanguageProgress] = useState(new Map());
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    // Parse saved progress from localStorage
    let savedProgress = localStorage.getItem("userChoices");
   

    try {
      savedProgress = JSON.parse(savedProgress);
    } catch (e) {
      savedProgress = [];
    }

    // Ensure savedProgress is an array
    if (!Array.isArray(savedProgress)) {
      savedProgress = [];
    }

    const progressData = new Map();

    savedProgress.forEach((data) => {
      progressData.set(data.language, {
        level: data.proficiency,
        progress: data.progress,
        skills: [
          { name: "Pronunciation", score: data.pronunciation },
          { name: "Understanding", score: data.understanding },
          { name: "Fluency", score: data.fluency },
          { name: "Grammar", score: data.grammar },
          { name: "Vocabulary", score: data.vocabulary },
          { name: "Filler Words", score: data.filler },
        ],
      });
    });
    setLanguageProgress(progressData);
  }, []);


  return (
    <Container>
      
       {/* Carousel for LanguageTestAnalysis */}
       <Carousel>
        {/* Iterate over the languageProgress Map and pass each tuple to LanguageTestAnalysis */}
        {Array.from(languageProgress.entries()).map(([language, data], index) => (
          <Grid container key={index} justifyContent="center">
            <Grid item xs={12}>
              <LanguageTestAnalysis language={language} data={data} test="test" />
            </Grid>
          </Grid>
        ))}
      </Carousel>


      <div
        className={classes.chatBotFullScreen}
        style={{ height: isTyping ? "100vh" : "100%" }}
      >
        <ChatBot
          handleTyping={() => setIsTyping(true)}
          setIsTyping={setIsTyping}
          isTyping={isTyping}
        />
      </div>
    </Container>
  );
};

export default Widgets;
