import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  factorCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    marginBottom: '10px',
    backgroundColor: '#1A222C', // Secondary background color
    color: '#BDC4CE', // Text color
    border: '1px solid #24303F', // Main background color
  },
  score: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#4caf50', // Default color
    color: '#000', // Black text color for better contrast
  },
}));

const FactorScore = ({ factor, score, color }) => {
  const classes = useStyles();

  return (
    <Card className={classes.factorCard}>
      <Typography variant="h6">{factor}</Typography>
      <div className={classes.score} style={{ backgroundColor: color }}>
        <Typography>{score}</Typography>
      </div>
    </Card>
  );
};

export default FactorScore;
