import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  Typography,
  Button,
  Container,
  CircularProgress,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import CategoryIcon from "@material-ui/icons/Category";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

// Define styles for the component using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(4),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: theme.spacing(2),
    backgroundColor: "#1A222C", // Secondary background for the card
    color: "#BDC4CE", // Neutral text color
    boxShadow: theme.shadows[3],
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.05)",
      boxShadow: theme.shadows[6],
    },
  },
  cardMedia: {
    width: "100%",
    height: 0,
    paddingTop: "56.25%", // 16:9 aspect ratio
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    textAlign: "center",
    color: "#BDC4CE", // Neutral text color
  },
  videoTitle: {
    fontWeight: "bold",
    fontFamily: "Poppins",
    marginBottom: theme.spacing(1),
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#FFFFFF", // Title text color (highlight)
  },
  videoDescription: {
    fontFamily: "Poppins",
    marginBottom: theme.spacing(2),
    color: "#BDC4CE", // Neutral text color for description
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    color: "#BDC4CE", // Neutral text color
  },
  streamButton: {
    backgroundColor: "#3C50E0", // Primary blue for the button
    color: "#FFFFFF", // White text for the button
    "&:hover": {
      backgroundColor: "#1e4db7", // Darker blue on hover
    },
    fontFamily: "Poppins",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    color: "#BDC4CE", // Neutral text color for icon descriptions
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    color: "#3C50E0", // Primary blue for loader
  },
  dialogContent: {
    position: "relative",
    padding: 0,
    overflow: "hidden",
    backgroundColor: "black", // Black background for video modal
    height: "calc(100vh - 64px)",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: "white", // White color for close button
    zIndex: 1,
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%", // Maintain 16:9 aspect ratio for video
    height: "100%",
    width: "100%",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100% !important", 
    width: "100% !important", 
  },
}));

const Stream = () => {
  // Use custom styles
  const classes = useStyles();

  // State variables for videos, loading status, error status, and selected video
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  // Fetch videos when the component mounts
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true); // Set loading to true when starting the fetch
        setError(false); // Reset any previous error state

        // Fetch videos from the API
        const { data } = await axios.get(
          "https://backend-explified.web.app/api/v1/video/get",
          {
            cancelToken: new axios.CancelToken((cancel) => (cancelRequest = cancel)),
          }
        );

        // Set the fetched videos to the state
        setVideos(data.data);
        setLoading(false); // Set loading to false when fetch is complete
      } catch (error) {
        if (axios.isCancel(error)) return; // Ignore cancellation errors
        setError(true); // Set error state if there was a problem fetching videos
        setLoading(false); // Set loading to false even if there was an error
        console.error(error); // Log the error to the console
      }
    };

    let cancelRequest;
    fetchVideos();

    // Cleanup function to cancel the API request if the component unmounts
    return () => {
      if (cancelRequest) {
        cancelRequest();
      }
    };
  }, []);

  // Handle closing of the video dialog
  const handleClose = () => {
    setSelectedVideo(null); // Set selected video to null to close the dialog
  };

  // Show loader while videos are loading
  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  // Show error message if there was a problem loading videos
  if (error) {
    return (
      <div className={classes.loader}>
        <Typography variant="h6" color="error">
          Failed to load videos. Please try again later.
        </Typography>
      </div>
    );
  }

  return (
    <Container maxWidth="md" className={classes.gridContainer}>
      {/* Container for the header and stream button */}
      <div className={classes.container}>
        <Typography variant="h4" className={classes.videoTitle}>
          Stream
        </Typography>
        <Button
          href="https://stream.explified.com/"
          target="_blank"
          className={classes.streamButton}
        >
          Go to Stream
        </Button>
      </div>
      {/* Grid to display video cards */}
      <Grid container spacing={3}>
        {videos.map((video) => (
          <Grid item xs={12} sm={6} md={4} key={video.videoID}>
            {/* Card for each video */}
            <Card
              className={classes.card}
              onClick={() => setSelectedVideo(video.videoID)} // Open video dialog on click
            >
              <CardMedia
                image={`https://img.youtube.com/vi/${video.videoID}/hqdefault.jpg`}
                className={classes.cardMedia}
                title={video.title}
              />
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" className={classes.videoTitle}>
                  {video.title}
                </Typography>
                <Typography variant="body2" className={classes.videoDescription}>
                  {video.description}
                </Typography>
                <div className={classes.iconText}>
                  <CategoryIcon style={{ marginRight: 4, color: "#3C50E0" }} />
                  <Typography variant="body2">{video.category}</Typography>
                </div>
                <div className={classes.iconText}>
                  <AccessTimeIcon style={{ marginRight: 4, color: "#3C50E0" }} />
                  <Typography variant="body2">{video.duration}</Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* Dialog to display the selected video */}
      <Dialog
        open={Boolean(selectedVideo)}
        onClose={handleClose}
        fullScreen
      >
        <div className={classes.dialogContent}>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${selectedVideo}`}
            playing
            controls
            width="100%"
            height="100%"
          />
        </div>
      </Dialog>
    </Container>
  );
};

export default Stream;

