import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    Container,
    CircularProgress,
    Chip,
    FormControlLabel,
    Checkbox,
    Collapse,
    Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        backgroundColor: '#121212',
    },
    header: {
        textAlign: 'center',
        color: '#ffffff',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 12,
        backgroundColor: '#1e1e1e',
        boxShadow: '0 4px 20px rgba(255, 255, 255, 0.1)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 25px rgba(255, 255, 255, 0.15)',
        },
    },
    cardMedia: {
        paddingTop: '56.25%',
        borderRadius: '12px 12px 0 0',
    },
    cardContent: {
        flexGrow: 1,
        fontFamily: 'Poppins, sans-serif',
        padding: theme.spacing(3),
        color: '#e0e0e0',
    },
    postTitle: {
        fontSize: '1.25rem',
        fontWeight: 600,
        color: '#ffffff',
        marginBottom: theme.spacing(1),
    },
    author: {
        color: '#bdbdbd',
        fontSize: '0.875rem',
        marginBottom: theme.spacing(1),
    },
    date: {
        color: '#bdbdbd',
        fontSize: '0.875rem',
        marginBottom: theme.spacing(1),
    },
    excerpt: {
        color: '#e0e0e0',
        fontSize: '0.95rem',
        lineHeight: 1.5,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
    },
    tags: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    tagChip: {
        marginRight: theme.spacing(1),
        backgroundColor: '#2c2c2c',
        color: '#e0e0e0',
        '&:hover': {
            backgroundColor: '#3c3c3c',
            cursor: 'pointer',
        },
    },
    loader: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main,
    },
    filterContainer: {
        marginBottom: theme.spacing(4),
    },
    categoryContainer: {
        marginBottom: theme.spacing(2),
        color: '#e0e0e0',
        backgroundColor: '#1e1e1e',
        borderRadius: 8,
        padding: theme.spacing(2),
    },
    categoryHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#ffffff',
        cursor: 'pointer',
    },
    categoryCheckbox: {
        color: '#ffffff',
    },
}));

const Blogs = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://backend-explified.web.app/api/v1/cms/post-category');
                setCategories(response.data.categories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            try {
                let url = 'https://backend-explified.web.app/api/v1/cms/posts/main-posts';
                if (selectedCategories.length > 0) {
                    const categoryQuery = selectedCategories.join(',');
                    url += `?category=${categoryQuery}`;
                }
                const response = await axios.get(url);
                setPosts(response.data.posts);
            } catch (error) {
                setError(true);
                console.error('Error fetching posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, [selectedCategories]);

    const handleCategoryChange = (category) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(category)
                ? prevSelected.filter((cat) => cat !== category)
                : [...prevSelected, category]
        );
    };

    if (loading) {
        return (
            <div className={classes.loader}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.loader}>
                <Typography variant="h6" color="error">
                    Failed to load posts. Please try again later.
                </Typography>
            </div>
        );
    }

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Typography className={classes.header}>Blogs</Typography>
            <div className={classes.filterContainer}>
                <div className={classes.categoryContainer}>
                    <div className={classes.categoryHeader} onClick={() => setCollapsed(!collapsed)}>
                        <Typography variant="h6">Filter by Categories</Typography>
                        <Button color="primary" variant="text">
                            {collapsed ? 'Show' : 'Hide'}
                        </Button>
                    </div>
                    <Collapse in={!collapsed}>
                        {categories.map((category) => (
                            <FormControlLabel
                                key={category.id}
                                control={
                                    <Checkbox
                                        checked={selectedCategories.includes(category.name)}
                                        onChange={() => handleCategoryChange(category.name)}
                                        className={classes.categoryCheckbox}
                                    />
                                }
                                label={category.name}
                            />
                        ))}
                    </Collapse>
                </div>
            </div>
            <Grid container spacing={4} justifyContent="center">
                {posts.map((post) => (
                    <Grid item xs={12} sm={6} md={4} key={post.id}>
                        <Card className={classes.card}>
                            <Link to={`/blog/${post.permalink}`} style={{ textDecoration: 'none' }}>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        image={post.featuredImage}
                                        title={post.title}
                                    />
                                    <CardContent className={classes.cardContent}>
                                        <Typography className={classes.postTitle}>{post.title}</Typography>
                                        <Typography className={classes.author}>
                                            By {post.author}
                                        </Typography>
                                        <Typography className={classes.date}>
                                            {new Date(post.createdAt._seconds * 1000).toLocaleDateString()}
                                        </Typography>
                                        <div className={classes.tags}>
                                            {post.tags.map((tag) => (
                                                <Chip
                                                    key={tag}
                                                    label={tag}
                                                    className={classes.tagChip}
                                                />
                                            ))}
                                        </div>
                                        <Typography className={classes.excerpt}>
                                            {post.excerpt}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Blogs;
