import React from "react";
import { Box, Typography, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  videoItem: {
    position: "relative",
    paddingBottom: "56.25%", // 16:9 aspect ratio
    height: 0,
    overflow: "hidden",
    borderRadius: "8px",
    marginBottom: theme.spacing(4),
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  title: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontFamily: "Poppins, sans-serif",
    marginBottom: theme.spacing(2),
  },
}));

const videoList = [
  { title: "Lesson 1", videoId: "video_id_1" },
  { title: "Lesson 2", videoId: "video_id_2" },
  { title: "Lesson 3", videoId: "video_id_3" },
  { title: "Lesson 4", videoId: "video_id_4" },
];

const VideoGallery = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Explore Our Videos
      </Typography>
      <Grid container spacing={4}>
        {videoList.map((video, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box className={classes.videoItem}>
              <iframe
                className={classes.iframe}
                src={`https://www.youtube.com/embed/${video.videoId}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
            <Typography variant="h6" style={{ color: "#BDC4CE" }}>
              {video.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VideoGallery;
