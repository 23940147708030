import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { Card, Typography, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: "#24303F", // Main background color
    padding: "20px",
    color: "#BDC4CE", // Text color
  },
  radarContainer: {
    flex: 1,
    padding: "20px",
    backgroundColor: "#1A222C", // Secondary background color
    marginRight: "20px",
  },
  taskListContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  taskCard: {
    padding: "10px 15px",
    backgroundColor: "#1A222C", // Secondary background color
    color: "#BDC4CE", // Text color
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  taskInfo: {
    flex: 1,
    marginRight: "10px",
  },
  taskScore: {
    color: "#4caf50", // Primary color
  },
  pendingScore: {
    color: "#ff9800", // Orange for pending tasks
  },
}));

const RadarGraph = ({ data }) => {
  const classes = useStyles();

  return (
    <Box className={classes.radarContainer}>
      <Typography variant="h6" gutterBottom>
        Language Proficiency
      </Typography>
      <RadarChart outerRadius={90} width={400} height={250} data={data}>
        <PolarGrid stroke="#BDC4CE" />
        <PolarAngleAxis dataKey="factor" stroke="#BDC4CE" />
        <PolarRadiusAxis angle={30} domain={[0, 100]} stroke="#BDC4CE" />
        <Radar
          name="Student"
          dataKey="score"
          stroke="#4caf50"
          fill="#4caf50"
          fillOpacity={0.7}
        />
      </RadarChart>
    </Box>
  );
};

const TaskList = () => {
  const classes = useStyles();

  const tasks = [
    { title: "Improve Pronunciation", score: "Good", pending: false },
    { title: "Expand Vocabulary", score: "Pending", pending: true },
    { title: "Increase Fluency", score: "Pending", pending: true },
  ];

  return (
    <Box className={classes.taskListContainer}>
      <Typography variant="h6" gutterBottom>
        Task List
      </Typography>
      {tasks.map((task, index) => (
        <Card key={index} className={classes.taskCard}>
          <Typography className={classes.taskInfo}>{task.title}</Typography>
          <Typography
            className={task.pending ? classes.pendingScore : classes.taskScore}
          >
            {task.score}
          </Typography>
        </Card>
      ))}
    </Box>
  );
};

const GraphAndTasks = ({ language, data }) => {
  const classes = useStyles();

  const radarData = data.map(item =>
    item.skills.map(skill => ({
      factor: skill.name,
      score: skill.score,
    }))
  ).flat();

  return (
    <Box className={classes.container}>
      <RadarGraph data={radarData} />
      <TaskList />
    </Box>
  );
};

export default GraphAndTasks;
