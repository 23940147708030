import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AppBar, Toolbar, Typography, Button, IconButton, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#23b5b5',
    padding: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  logo: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: 'white',
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
  buttonGroup: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
}));

const ToolBar = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleLoginSignupClick = () =>{
    history.push('/teacher-dashboard');
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <div className={classes.logo}></div>
          <Typography variant="h6" className={classes.title}>
            Explified
          </Typography>
        </div>
        <div className={classes.buttonGroup}>
          <IconButton className={classes.button} aria-label="help">
            <HelpOutlineIcon />
          </IconButton>
          <Button
            color="inherit"
            className={classes.button}
            startIcon={<AccountCircleIcon />}
            onClick={handleLoginSignupClick}
            >
            Login/Signup
        </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default ToolBar;
