import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as images from "../../assets/images"; // Replace with your image paths
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    width:"95%",
    display: "flex",
    margin: theme.spacing(0, 2),
    padding: theme.spacing(2),
    fontFamily: "Poppins",
    boxShadow: `0 4px 12px ${theme.palette.grey[700]}`,
    borderRadius: "12px",
    transition: "transform 0.3s ease-in-out",
    backgroundColor: "#1A222C", // Dark background for the card
    color: "#BDC4CE", // Light text color
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: `0 6px 16px ${theme.palette.grey[900]}`,
    },
  },
  media: {
    width: 150,
    height: 150,
    borderRadius: "8px",
    backgroundSize: "cover", // Ensures image covers the area
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: theme.spacing(2),
  },
  button: {
    alignSelf: "center",
    fontFamily: "Poppins",
    backgroundColor: "#3C50E0", // Primary button color
    borderRadius: "8px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#2a3c8f", // Darker shade for hover effect
    },
  },
  searchField: {
    width: "95%",
    margin: theme.spacing(2),
    fontFamily: "Poppins",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#2D2D2D", // Dark background for input
    },
    "& .MuiInputLabel-outlined": {
      color: "#BDC4CE", // Light label color
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#BDC4CE", // Light border color
    },
    "& .MuiInputBase-input": {
      color: "#BDC4CE", // Light text color
    },
  },
}));

const Apps = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  // Dummy Available Apps Data <- should be changed when called from the backend.
  const appsData = [
    {
      id: 1,
      name: "Explified for Windows",
      description: "This is the Windows version of the Application.",
      image: images.windows,
      link: "https://apps.microsoft.com/home?hl=en-us&gl=IN",
    },
    {
      id: 2,
      name: "Explified for Android",
      description: "This is the Android version of the Application.",
      image: images.android,
      link: "https://play.google.com/store/games?hl=en",
    },
    {
      id: 3,
      name: "Explified for Apple",
      description: "This is the Apple version of the Application.",
      image: images.apple,
      link: "https://www.apple.com/in/app-store/",
    },
    {
      id: 4,
      name: "Explified for Chrome",
      description: "This is the Google Chrome version of the Application.",
      image: images.chrome,
      link: "https://chromewebstore.google.com/",
    },
  ];

  const filteredApps = appsData.filter((app) =>
    app.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <TextField
        className={classes.searchField}
        label="Search..."
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Grid container direction="column" spacing={2}>
        {filteredApps.map((app) => (
          <Grid item key={app.id}>
            <Card className={classes.card} elevation={3}>
              <CardMedia
                className={classes.media}
                image={app.image}
                title={app.name}
              />
              <CardContent className={classes.content}>
                <Typography
                  variant="h6"
                  style={{ fontFamily: "Poppins", color: "#BDC4CE" }}
                >
                  {app.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontFamily: "Poppins", color: "#BDC4CE" }}
                >
                  {app.description}
                </Typography>
              </CardContent>
              <Button
                variant="contained"
                className={classes.button}
                href={app.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Coming Soon
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Apps;
