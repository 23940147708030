import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Snackbar,
  IconButton,
  makeStyles,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AppIcon from "../../assets/images/explified.jpeg"; // Update this with the path to your app icon
import BackgroundImage from "../../assets/images/background.jpg"; // Update this with the path to your background image
import LoginModal from "./LoginModal"; // Importing LoginModal component
import axios from "axios"; // Importing axios for making HTTP requests
import { CheckBox } from "@material-ui/icons";
import { useHistory } from "react-router-dom"; // Importing useHistory hook for navigation
import GetStartedUser from "./GetStartedUser"; // Importing GetStartedUser component
import { FaGoogle } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  // Custom styles for the component
  dialogPaper: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    width: "100vw",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#fff",
  },
  appIconWrapper: {
    position: "absolute",
    left: theme.spacing(2),
    top: theme.spacing(8),
    display: "flex",
    alignItems: "center",
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  appIcon: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(1),
  },
  appText: {
    fontSize: "4rem",
    fontWeight: "bold",
    color: "black",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(4),
    color: "#fff",
  },
  description: {
    maxWidth: "50%",
    color: "black",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    backgroundColor: "#fff",
    borderRadius: "15px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "75px",
    paddingBottom: "75px",
  },
  button: {
    margin: theme.spacing(1),
    color: "#003262",
    fontSize: "17px",
    fontWeight: "bold",
    height: "50px",
    background: "linear-gradient(-45deg, #b597f6, #96c6ea)",
    marginTop: "15px",
  },
  primaryButton: {
    backgroundColor: "#23b5b5",
  },
  secondaryButton: {
    backgroundColor: "#f57c00",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    fontFamily: "Poppins, sans-serif",
  },
  textField: {
    fontFamily: "Poppins, sans-serif",
    "& .MuiInputBase-root": {
      height: "100%",
      width: "500px",
      fontFamily: "Poppins",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Poppins",
    },
    marginBottom: "15px",
  },
  loginText: {
    color: "#000",
    fontSize: "32pt",
    fontWeight: "bolder",
    alignSelf: "center",
    marginBottom: "25px",
    background: "linear-gradient(45deg, #919bff, #133a94)",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
  },
  textBtn: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    marginBottom: "10px",
  },
  checkbox: {
    color: "#000",
    marginLeft: "0px",
    fontFamily: "Poppins, sans-serif",
  },
  link: {
    color: "#000",
    cursor: "pointer",
  },
  switchText: {
    color: "#6e78ff",
    fontSize: "13px",
    alignSelf: "center",
    cursor: "pointer",
  },
}));

function LoginPopup({ open, onClose }) {
  const classes = useStyles(); // Apply styles from useStyles hook
  const [openLoginModal, setOpenLoginModal] = useState(false); // State to manage the visibility of LoginModal
  const [openGetStarted, setOpenGetStarted] = useState(false); // State to manage the visibility of GetStartedUser
  const [email, setEmail] = useState(""); // State to hold email input
  const [password, setPassword] = useState(""); // State to hold password input
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State to hold Snackbar message

  // Function to handle login form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to the login API
      const response = await axios.post(
        "https://explified-backend.web.app/api/v1/auth/login",
        {
          email,
          password,
        },
        {
          withCredentials: true, // Include credentials for cross-origin requests
        }
      );
      const { token } = response.data; // Extract token from response
      console.log(response.data);
      localStorage.setItem("authtoken", token); // Store token in local storage

      setSnackbarMessage("Login successful!"); // Set success message
      setSnackbarOpen(true); // Open Snackbar

      setOpenGetStarted(true); // Open GetStartedUser dialog
    } catch (error) {
      console.log("entered catch block");
      // Set error message based on response or default error
      setSnackbarMessage(
        error.response
          ? error.response.data.message
          : "An error occurred during login."
      );
      setSnackbarOpen(true); // Open Snackbar with error message
    }
  };

  // Function to close Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Function to open LoginModal
  const handleLoginClick = () => {
    setOpenLoginModal(true);
  };

  // Function to close LoginModal
  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  // Function to close GetStartedUser dialog
  const handleCloseGetStarted = () => {
    setOpenGetStarted(false);
  };

  return (
    <>
      <Dialog
        open={open} // Control Dialog visibility with open prop
        onClose={onClose} // Trigger onClose function when Dialog is closed
        fullScreen // Make the Dialog fullscreen
        aria-labelledby="login-popup-title"
        classes={{ paper: classes.dialogPaper }} // Apply custom styles to Dialog paper
      >
        {/* Close Button at the top right corner */}
        <IconButton
          edge="end"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {/* App icon and title */}
        <div className={classes.appIconWrapper}>
          <img src={AppIcon} alt="App Icon" className={classes.appIcon} />
          <Typography
            className={classes.appText}
            style={{ fontFamily: "Poppins" }}
          >
            Explified
          </Typography>
        </div>

        {/* Dialog content including login form */}
        <DialogContent>
          <div className={classes.contentWrapper}>
            <div className={classes.description}>
              <Typography
                variant="h3"
                style={{ fontWeight: "bold", fontFamily: "Poppins" }}
              >
                Welcome Back!
              </Typography>
              <Typography variant="h4" paragraph>
                Ready to Continue Your Language Journey?
              </Typography>
            </div>
            <div className={classes.buttonGroup}>
              {/* Login Form */}
              <form className={classes.form}>
                <p className={classes.loginText}>Login</p>
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Update email state
                  className={classes.textField}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // Update password state
                  className={classes.textField}
                />
                <div className={classes.textBtn}>
                  <FormControlLabel
                    control={<CheckBox color="primary" />}
                    label="Remember me"
                    className={classes.checkbox}
                    onChange={() => alert("remeber me !!!")}
                  />
                  <p
                    variant="body2"
                    className={classes.link}
                    onClick={() => alert("Forgot Password Clicked !!")} // Alert on click
                  >
                    Forgot Password!
                  </p>
                </div>
                <Button
                  type="submit"
                  onClick={handleLogin} // Handle form submission
                  className={`${classes.button} ${classes.primaryButton}`}
                >
                  Login
                </Button>
              </form>
              <Button
                variant="contained"
                onClick={handleLoginClick} // Open LoginModal
                className={`${classes.button} ${classes.secondaryButton}`}
              >
                Sign In with {<span 
                style={{  marginLeft: '10px',
                  backgroundColor: 'white',
                  display: 'flex', // Use flex to center the icon
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '5px',
                  borderRadius: '50%',
                  width: '24px',  // Fixed width for consistent sizing
                  height: '24px', // Fixed height for consistent sizing 
                  }}>
                          <FaGoogle style={{ color: '#4285F4' }}/></span>}
              </Button>
              <p className={classes.switchText}>
                New here?{" "}
                <span onClick={() => alert("Sign Up Clicked !!")}>
                  Create an account
                </span>
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* LoginModal component */}
      <LoginModal open={openLoginModal} onClose={handleCloseLoginModal} />

      {/* GetStartedUser component */}
      <GetStartedUser open={openGetStarted} onClose={handleCloseGetStarted} />

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}

export default LoginPopup;
