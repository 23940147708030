// Profile.js
import React, { useState } from "react";
import {
  Container,
  Paper,
  Avatar,
  TextField,
  IconButton,
  Grid,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import userImage from "../../assets/images/user.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#1A222C",
    color: "#BDC4CE",
    borderRadius: "8px",
    padding: theme.spacing(4),
  },
  profileImage: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
    borderRadius: "50%",
    border: `4px solid #3C50E0`,
  },
  paper: {
    padding: theme.spacing(2),
    width: "100%",
    maxWidth: 600,
    marginBottom: theme.spacing(2),
    backgroundColor: "#24303F",
    color: "#BDC4CE",
    borderRadius: "8px",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  textField: {
    flex: 1,
    marginRight: theme.spacing(1),
    backgroundColor: "#1A222C",
  },
  iconButton: {
    padding: 10,
    color: "#3C50E0",
  },
  section: {
    marginTop: theme.spacing(2),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    marginRight: theme.spacing(1),
    color: "#3C50E0",
  },
  button: {
    marginTop: theme.spacing(2),
    color: "#FFF",
    backgroundColor: "#3C50E0",
    "&:hover": {
      backgroundColor: "#3348B0",
    },
  },
}));

const Profile = ({ onBeATutorClick }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState({
    name: false,
    email: false,
    password: false,
    address: false,
  });
  const [profile, setProfile] = useState({
    name: "John Doe",
    email: "john.doe@example.com",
    password: "********",
    address: "1234 Main St, Springfield, USA",
  });

  const handleEditClick = (field) => {
    setIsEditing((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Container className={classes.container}>
      <Avatar className={classes.profileImage} src={userImage} alt="Profile Image" />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {["name", "email", "password", "address"].map((field) => (
            <Grid item xs={12} key={field} className={classes.fieldContainer}>
              <TextField
                className={classes.textField}
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={profile[field]}
                onChange={handleInputChange}
                disabled={!isEditing[field]}
                variant="outlined"
                fullWidth
                InputProps={{ style: { color: "#BDC4CE" } }}
                InputLabelProps={{ style: { color: "#BDC4CE" } }}
              />
              <IconButton className={classes.iconButton} onClick={() => handleEditClick(field)}>
                {isEditing[field] ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Account Settings
        </Typography>
        <Box className={classes.section}>
          <Typography variant="subtitle1">
            <LockIcon className={classes.icon} />
            Change Password
          </Typography>
          <IconButton className={classes.iconButton}>
            <LockIcon />
          </IconButton>
        </Box>
        <Box className={classes.section}>
          <Typography variant="subtitle1">
            <ExitToAppIcon className={classes.icon} />
            Logout
          </Typography>
          <IconButton className={classes.iconButton}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Paper>

      {/* "Be A Tutor" button added */}
      <Button
        className={classes.button}
        fullWidth
        variant="contained"
        onClick={onBeATutorClick}
      >
        Be A Tutor
      </Button>
    </Container>
  );
};

export default Profile;
