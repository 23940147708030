import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, Grid, Card, CardContent, CardMedia, Box, makeStyles, IconButton,Icon, Avatar, Dialog, DialogTitle, DialogActions,DialogContent,DialogContentText, Link } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { endianness } from 'os';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useHistory } from "react-router-dom";


// Custom styles
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
  },
  searchSection: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
    color: 'white',
    padding: theme.spacing(2),
  },
  tutorCard: {
    maxWidth: 345,
    // margin: theme.spacing(2),
  },
  tutorImage: {
    height: 140,
    objectFit: 'cover',
    borderRadius:'8px',
  },
  footer: {
    backgroundColor: '#f1f1f1',
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Preply = () => {
  const classes = useStyles();
  const history = useHistory();
  const [favcolor, setfavcolor] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter =() => setOpenFilter(true);
  const handleCloseFilter =() => setOpenFilter(false);

  const [openSubs, setopenSubs] = useState(false);

  const handleOpenSubs =() => setopenSubs(true);
  const handleCloseSubs =() => setopenSubs(false);




  // Dummy tutor data
  const tutors = [
    { name: 'John Doe', subject: 'English', rating: 4.5, hourlyRate: '$20', imageUrl: 'https://via.placeholder.com/150' },
    { name: 'Jane Smith', subject: 'English', rating: 4.8, hourlyRate: '$25', imageUrl: 'https://via.placeholder.com/150' },
    { name: 'Michael Johnson', subject: 'English', rating: 4.2, hourlyRate: '$22', imageUrl: 'https://via.placeholder.com/150' },
    // Add more tutors as needed
  ];

  return (
    <div>
      {/* App Bar */}
      <AppBar position="static" className={classes.appBar}>
        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}} >
          <div style={{ display: 'flex', alignItems:'center'}}>
           <Avatar alt='Explified' src='src\assets\images\explified.jpeg'></Avatar>
            <Typography variant="h6">Explified</Typography>
          </div>
          <div style={{display: 'flex', alignItems:'center'}}>
            <Button color='white' variant='contained' href='' style={{marginLeft: 10, border: 10, justifyContent:'flex-end'}}
              onClick={handleOpenSubs}
            >
              Subscribe
            </Button>
        
            <Button color='white' variant='outlined' href='' style={{marginLeft: 10, border: 10, justifyContent:'flex-end'}}
            onClick={()=>{
              history.push("/refer");
            }}
            >
              Refer a friend
            </Button>
            <IconButton
              onClick={()=>{
                setfavcolor(prevColor => !prevColor);
              }}
            >
              <FavoriteBorderIcon style={{color: favcolor ? 'red' : 'primary'}}/>
            </IconButton>
            <IconButton>
              <NotificationsNoneIcon/>
            </IconButton>
            <IconButton >
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {/* Search Section */}
      <div className={classes.searchSection}>
        <Typography variant="h4" gutterBottom>
          Find the best English tutors
        </Typography>
        <div style={{alignItems:'center'}}>

          <TextField
            variant="outlined"
            label="Search by language or subject"
            fullWidth
            style={{ maxWidth: 600, margin: 'auto',marginBottom:'10px',background:'whitesmoke',borderRadius:'8px' }}
          />
          <IconButton
          onClick={handleOpenFilter}
            style={{
              marginLeft: '10px',
              backgroundColor: '#3f51b5',
              color:'wheat',
              padding: '10px',
              borderRadius: '8px',
            }}
          ><FilterListIcon/></IconButton>

          <Dialog open={openFilter} onClose={handleCloseFilter}>
          <DialogTitle>Filter Tutors</DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Filter by Subject"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Filter by Language"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Filter by Rating"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCloseFilter} color="primary">
            Apply Filters
          </Button>
        </DialogActions>

          </Dialog>

          <Dialog open ={openSubs} onClose={handleCloseSubs}>
            <DialogTitle style={{display:'flex', justifyContent:'center'}}>No Tutors here yet</DialogTitle>
            <DialogContent>
              <Typography variant='body1' style={{display:'flex', justifyContent:'center'}}>
              Explore our friendly and expert tutors, then book the one you like most. Excited to start reaching your learning goals?
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseSubs} color="primary" variant='outlined' style={{backgroundColor:'blueviolet', color:'white'}}>
                Find a Tutor
              </Button>
            </DialogActions>

          </Dialog>
         
        </div>
      </div>

      {/* Tutor Cards Section */}
    <Grid container spacing={2}>
      {tutors.map((tutor, index) => (
        <Grid container item key={index} spacing={2}>
          {/* Main 3:1 Column Grid */}
          <Grid item xs={9}>
            {/* Nested Grid for Image, Details, and Buttons */}
            <Grid container spacing={2}>
              {/* Tutor Image Section */}
              <Grid item xs={3}>
                <CardMedia
                  component="img"
                  alt={tutor.name}
                  image={tutor.imageUrl}
                  title={tutor.name}
                  className={classes.tutorImage}

                />
              </Grid>
              
              {/* Tutor Details Section */}
              <Grid item xs={6}>
                <CardContent>
                  <Typography variant="h6" style={{color:'white'}}>{tutor.name}</Typography>
                  <Typography variant="body2" color="gray">
                    {tutor.subject}
                  </Typography>
                  <Box className={classes.rating}>
                    <Rating value={tutor.rating} readOnly />
                    <Typography variant="body2" style={{ marginLeft: 5 , color:'gray'}}>
                      {tutor.rating} stars
                    </Typography>
                  </Box>
                  <Typography variant="body2" style={{color:'white'}}>
                    {tutor.hourlyRate} per hour
                  </Typography>
                </CardContent>
              </Grid>
              
              {/* Buttons Section */}
              <Grid item xs={3} container direction="column" justifyContent="center">
                <Button variant="contained" color="primary" fullWidth style={{ marginBottom: 10 }}>
                  Book a Trial
                </Button>
                <Button variant="outlined" color="primary" fullWidth>
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Reference Video Section */}
          <Grid item xs={3}>
            <CardMedia
              component="video"
              src="path/to/dummy/video.mp4" // Replace with actual video URL or video element
              title="Demo Video"
              controls
              className={classes.referenceVideo} // You may define styling for video if needed
            />
          </Grid>
        </Grid>
      ))}
    </Grid>

      {/* Footer */}
      <div className={classes.footer}>
        <Typography variant="body2" color="textSecondary">
          © 2024 Explified. All rights reserved.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Privacy Policy | Terms of Service
        </Typography>
      </div>
    </div>
  );
};

export default Preply;
