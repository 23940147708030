import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { RiProfileFill } from "react-icons/ri";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: "#23b5b5",
    color: "white",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaperClose: {
    width: 72,
    [theme.breakpoints.up("sm")]: {
      width: 72,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#23b5b5",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: theme.spacing(2.4),
    paddingLeft: theme.spacing(2),
  },
  icon: {
    color: "white",
  },
  listItemText: {
    opacity: (props) => (props.open ? 1 : 0),
    transition: "opacity 0.3s",
    fontFamily:"Poppins",
  },
  selected: {
    color: "#ffd700",
  },
}));

const SideBarTeacher = ({ selectedItem, onItemSelected }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon /> },
    { text: "Manage Account", icon: <AccountCircleIcon/> },
  ];

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: open ? classes.drawerPaper : classes.drawerPaperClose,
      }}
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          onClick={handleDrawerToggle}
          className={classes.icon}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        {open && (
          <Typography
            variant="h6"
            noWrap
            style={{ cursor: "pointer" }}
          >
            Explified
          </Typography>
        )}
      </Toolbar>
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={item.external ? "a" : "div"}
            href={item.external ? item.link : undefined}
            target={item.external ? "_blank" : undefined}
            onClick={
              !item.external ? () => onItemSelected(item.text) : undefined
            }
            className={selectedItem === item.text ? classes.selected : ""}
          >
            <ListItemIcon
              className={
                selectedItem === item.text ? classes.selected : classes.icon
              }
            >
              {item.icon}
            </ListItemIcon>
            {open && (
              <ListItemText
                primary={item.text}
                className={classes.listItemText}
              />
            )}
          </ListItem>
        ))}
      </List>
      <List style={{ marginTop: "auto" }}>
        <ListItem button component="a" href="/dashboard">
          <ListItemIcon>
            <ExitToAppIcon className={classes.icon} />
          </ListItemIcon>
          {open && (
            <ListItemText
              primary="Back To UserDashboard"
              secondary="tech@explified.com"
              className={classes.listItemText}
            />
          )}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideBarTeacher;
