import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { video } from "../../assets/images/index";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    textAlign: "center",
    backgroundColor: "#010103", // Dark theme background
    padding: theme.spacing(6, 4),
    borderRadius: "8px",
    color: "#BDC4CE", // Neutral color for text
  },
  heading: {
    fontFamily: "Poppins, sans-serif",
    color: "#FFFFFF",
    fontSize: "3rem",
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  subheading: {
    fontFamily: "Poppins, sans-serif",
    color: "#BDC4CE",
    fontSize: "1.1rem",
    fontWeight: 400,
    marginBottom: theme.spacing(4),
  },
  container: {
    textAlign: "center",
    backgroundColor: "#010103", // Dark theme background
    padding: theme.spacing(4),
    borderRadius: "8px",
  },
  image: {
    width: "100%",
    maxWidth: 600,
    height: "auto",
    borderRadius: "8px",
  },
  title: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontFamily: "Poppins, sans-serif",
    marginTop: theme.spacing(2),
  },
  link: {
    color: "#3C50E0",
    marginTop: theme.spacing(1),
    display: "block",
    fontSize: "1.1rem",
    textDecoration: "none",
  },
}));

const FeaturedVideo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.sectionContainer}>
      {/* Section Heading and Subheading */}
      <Typography variant="h3" className={classes.heading}>
        Explore Our Video Tutorials
      </Typography>
      <Typography variant="h6" className={classes.subheading}>
        Learn and grow with our curated tutorials and insights.
      </Typography>

      {/* Featured Video */}
      <Box className={classes.container}>
        <a
          href="https://www.youtube.com/@Explified"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <img
            src={video}
            alt="Featured Video"
            className={classes.image}
          />
        </a>
        <Typography variant="h5" className={classes.title}>
          Check out our YouTube Channel for more tutorials!
        </Typography>
        <a
          href="https://www.youtube.com/@Explified"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <Typography>
            Visit our YouTube Channel
          </Typography>
        </a>
      </Box>
    </Box>
  );
};

export default FeaturedVideo;
