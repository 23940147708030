import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme, CssBaseline } from "@material-ui/core";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Dashboard from "./components/userdashBoard/Dashboard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Privacy from "./components/footer/Privacy";
import Terms from "./components/footer/Terms";
import Landing from "./components/beATutor/Landing";
import DashBoardTeacher from "./components/beATutor/DashBoardTeacher";
import Disclaimer from "./components/footer/Disclaimer";
import referFriendLanding from "./components/referFriend/referFriendLanding";
import Blogs from "./components/blog/Blogs";
import BlogDetail from "./components/blog/BlogDetail";


function App() {
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(false); // State for dark mode

  //Create light and dark themes
  const theme = createMuiTheme({
    palette: {
      type: darkMode ? "dark" : "light",
    },
  });

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      {/* <CssBaseline /> Global styles reset */}
      {location.pathname !== "/dashboard" &&
        location.pathname !== "/privacy-policy" &&
        location.pathname !== "/terms-of-service" &&
        location.pathname !== "/be-a-tutor" &&
        location.pathname !== "/teacher-dashboard" && 
        location.pathname !== "/refer" && 
        (
          <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
        )}{" "}
      {/* Pass darkMode & setDarkMode */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/terms-of-service" component={Terms} />
        <Route path="/be-a-tutor" component={Landing} />
        <Route path="/teacher-dashboard" component={DashBoardTeacher} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/refer" component={referFriendLanding}/>
      
        <Route exact path="/blogs" component={Blogs} />
        <Route path="/blog/:permalink" component={BlogDetail} />
      </Switch>
      {location.pathname !== "/dashboard" &&
        location.pathname !== "/teacher-dashboard" && <Footer />}
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
