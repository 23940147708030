import React from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as images from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    padding: theme.spacing(8, 0),
    // backgroundColor: '#f5f5f5',
  },
  heroText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  heroImage: {
    width: '100%',
    height: 'auto',
    borderRadius:'8px',
  },
  messageText: {
    marginBottom: theme.spacing(4),
    color:'wheat'
  },
}));

const Hero = () => {
  const classes = useStyles();

  const handleInviteClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Join Our Application!',
          text: 'Check out this amazing app! Click the link to get started:',
          url: 'https://explified.com/',
        })
        .then(() => console.log('Invite sent successfully'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  return (
    <Container className={classes.heroContainer} maxWidth="lg">
      <Grid container spacing={4}>
        {/* Left Side - Text Section */}
        <Grid item xs={12} md={6} className={classes.heroText}>
          <Typography variant="h3" component="h1" gutterBottom style={{color:'white'}}>
            REFER A FRIEND AND GET EXCITING OFFERS
          </Typography>
          <Typography variant="h6" className={classes.messageText}>
            Discover amazing features and share this experience with your friends!
          </Typography>
          <Button variant="contained" color="primary" onClick={handleInviteClick}>
            Invite Friends
          </Button>
        </Grid>

        {/* Right Side - Image Section */}
        <Grid item xs={12} md={6}>
          <img
            src= {images.referFriend}
            alt="Hero Section"
            className={classes.heroImage}
        
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Hero;
