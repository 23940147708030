import React from 'react'
import Hero from './Hero'
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import ReferJourney from './referJourney';
import FAQ from './FAQ';

const referFriendLanding = () => {
  return (
    <div>
        <AppBar position='static' style={{color:'#000000'}}>
            <Toolbar style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Typography variant='h6'
                    component={Link}
                     to="/dashboard"
                     style={{color:'white'}}
                > 
                    Explified
                </Typography>

            </Toolbar>
        </AppBar>
       
        <Hero/>
        <ReferJourney/>
        <FAQ/>
        

    </div>
  )
}

export default referFriendLanding