import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Paper,
  Container,
  Grid,
} from "@material-ui/core";
import SideBarTeacher from "./SideBarTeacher"; // Ensure the path is correct
import { makeStyles } from "@material-ui/core/styles";
import ManageAccount from "./ManageAccount";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#23b5b5",
    paddingInline: '50px',
  },
  title: {
    flexGrow: 1,
    fontFamily:"Poppins",
  },
  avatar: {
    marginInline: theme.spacing(2),
  },
  content: {
    flexGrow: 5,
    // padding: theme.spacing(3),
    marginLeft: 50, // Adjust for sidebar width
  },
}));

const DashBoardTeacher = () => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState("DashboardTeacher");
  const [teacherProfile, setTeacherProfile] = useState({
    firstName: '',
    lastName: '',
    countryOfBirth: '',
    contactNumber: '',
    email: '',
  });

  useEffect(() => {
    const storedProfile = JSON.parse(localStorage.getItem('teacherProfile'));
    if (storedProfile) {
      setTeacherProfile(storedProfile);
    }
  }, []);

  const handleItemSelected = (item) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Manage Account":
        return <ManageAccount open={true} onClose={() => setSelectedItem("DashboardTeacher")} />;
      default:
        return (
          <Paper className={`${classes.paper} ${classes.paperMain}`}>
            <Typography variant="h6" gutterBottom>
              Welcome to the Learning Dashboard!
            </Typography>
            <Typography>
              Here you can track your courses, see your progress, and much more.
            </Typography>
          </Paper>
        );
    }
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            Explified
          </Typography>
          <IconButton edge="end">
            <Avatar className={classes.avatar} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <SideBarTeacher selectedItem={selectedItem} onItemSelected={handleItemSelected} />
      <main className={classes.content}>
        <Toolbar />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <SideBarTeacher
                selectedItem={selectedItem}
                onItemSelected={handleItemSelected}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              {renderContent()}
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default DashBoardTeacher;

