import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  accordion: {
    width: '100%',
    maxWidth: 800,
    marginBottom: theme.spacing(2),
    borderRadius:'8px',
  },
  question: {
    fontWeight: 'bold',
  },
  answer: {
    color: '#555',
  },
}));

const FAQ = () => {
  const classes = useStyles();

  const faqData = [
    {
      question: 'What is the purpose of this application?',
      answer: 'This application is designed to help users connect and share their journey with friends.',
    },
    {
      question: 'How do I invite a friend?',
      answer: 'You can invite a friend by clicking on the "Invite Friends" button on the home screen.',
    },
    {
      question: 'Are there any rewards for inviting friends?',
      answer: 'Yes! You can unlock special rewards and features by inviting friends to join the platform.',
    },
  ];

  return (
    <Container className={classes.container}>
      <Grid container direction="column" alignItems="center">
        <Typography variant="h4" gutterBottom style={{color:'white'}}>
          Frequently Asked Questions
        </Typography>

        {faqData.map((faq, index) => (
          <Accordion key={index} className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-content-${index}`}
              id={`faq-header-${index}`}
            >
              <Typography className={classes.question}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.answer}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Container>
  );
};

export default FAQ;
