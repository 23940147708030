import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
  Collapse,
  Avatar,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SchoolIcon from "@material-ui/icons/School";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LinkIcon from "@material-ui/icons/Link";
import MenuIcon from "@material-ui/icons/Menu";
import AppsIcon from "@material-ui/icons/Apps";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NotesIcon from "@material-ui/icons/Notes";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import MicIcon from "@material-ui/icons/Mic";
import * as images from "../../assets/images";

const drawerWidth = 240; // Constant for drawer width

// Styles for the SideBar component using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: "#1A222C", // Dark secondary background for sidebar
    color: "#BDC4CE", // Sidebar text color
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaperClose: {
    width: 72,
    [theme.breakpoints.up("sm")]: {
      width: 72,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#1A222C", // Closed sidebar dark background
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2.4),
    paddingLeft: theme.spacing(2),
    backgroundColor: "#24303F", // Darker background for toolbar
    color: "#BDC4CE", // Text color in the toolbar
  },
  icon: {
    color: "#BDC4CE", // Default icon color
  },
  listItemText: {
    opacity: (props) => (props.open ? 1 : 0),
    transition: "opacity 0.3s",
  },
  selected: {
    color: "#3C50E0", // Primary blue for selected item
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
    backgroundColor: "#24303F", // Nested items' background
  },
  iconSelected: {
    color: "#3C50E0", // Highlighted icon color for selected items
  },
}));

// SideBar component definition
const SideBar = ({ selectedItem, onItemSelected, open, setOpen }) => {
  // State variables
  const [showBack, setShowBack] = useState(false); // Toggle back button
  const [dashboardOpen, setDashboardOpen] = useState(false); // Toggle dashboard menu
  const [tutorsOpen, setTutorsOpen] = useState(false); // Toggle tutors menu
  const classes = useStyles({ open }); // Use styles with the open prop
  const history = useHistory(); // React Router's history object for navigation

  // Logout function using axios to make a POST request
  const handleLogout = async () => {
    try {
      await axios.post("https://backend-explified.web.app/api/v1/auth/logout");
      localStorage.removeItem("authtoken"); // Remove auth token from local storage
      history.push("/"); // Redirect to home
    } catch (error) {
      console.error("Logout Failed", error);
    }
  };

  // Handle drawer toggle (open/close)
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // Handle "Be A Tutor" button click
  const handleBeATutorClick = () => {
    setOpen(false);
    setShowBack(true);
    onItemSelected("Be A Tutor"); // Call parent handler with "Be A Tutor" item
  };

  // Handle "Back to Student" button click
  const handleBackToStudentClick = () => {
    setShowBack(false);
    setOpen(true);
    onItemSelected("Dashboard"); // Call parent handler with "Dashboard" item
  };

  // Handle "Profile" button click
  const handleProfileClick = () => {
    onItemSelected("Profile"); // Call parent handler with "Profile" item
  };

  // Handle "Tutors" button click
  const handleTutorsClick = () => {
    setTutorsOpen(!tutorsOpen); // Toggle tutors menu
    setDashboardOpen(false); // Close dashboard menu if open
    onItemSelected("Tutors"); // Call parent handler with "Tutors" item
  };

  // Handle "Dashboard" button click
  const handleDashBoardClick = () => {
    setDashboardOpen(!dashboardOpen); // Toggle dashboard menu
    setTutorsOpen(false); // Close tutors menu if open
    onItemSelected("Dashboard"); // Call parent handler with "Dashboard" item
  };

  // Handle "Notes" button click
  const handleNotesClick = () => {
    onItemSelected("Notes"); // Call parent handler with "Notes" item
  };

  // Menu items configuration
  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon /> },
    { text: "Stream", icon: <LinkIcon /> },
    { text: "Courses", icon: <SchoolIcon /> },
    { text: "Apps", icon: <AppsIcon /> },
    { text: "Notes", icon: <NotesIcon /> },
    { text: "Vocabuluary", icon: <RecordVoiceOverIcon /> },
    { text: "Voice Assistant", icon: <MicIcon /> },

    // { text: "Playground", icon: <ToysIcon /> },
    {
      text: "Tutors",
      icon: <SchoolIcon />,
      onClick: handleTutorsClick,
      nestedItems: [
        {
          text: "Be A Tutor",
          icon: <PersonAddIcon />,
          onClick: handleBeATutorClick,
        },
      ],
    },
  ];

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: open ? classes.drawerPaper : classes.drawerPaperClose, // Change drawer style based on open state
      }}
      open={open}
      onMouseEnter={() => setOpen(true)} // Open drawer on mouse enter
      onMouseLeave={() => setOpen(false)} // Close drawer on mouse leave
    >
      <Toolbar className={classes.toolbar}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.icon}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          {open && !showBack && (
            <Typography
              variant="h6"
              noWrap
              onClick={handleProfileClick}
              style={{ cursor: "pointer" }}
              endIcon={<AccountCircleIcon />}
            >
              Profile
            </Typography>
          )}
        </div>
        {open && (
          <Avatar
            alt="Profile Picture"
            src={images.user}
            className={classes.avatar}
            onClick={handleProfileClick}
          />
        )}
      </Toolbar>
      <List>
        {!showBack ? (
          menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={
                  item.onClick ||
                  (!item.external ? () => onItemSelected(item.text) : undefined)
                }
                className={selectedItem === item.text ? classes.selected : ""}
              >
                <ListItemIcon
                  className={
                    selectedItem === item.text ? classes.selected : classes.icon
                  }
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    className={classes.listItemText}
                  />
                )}
                {item.nestedItems && // Show expand icon if there are nested items
                  open &&
                  (item.text === "Dashboard" ? (
                    dashboardOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  ) : item.text === "Tutors" ? (
                    tutorsOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  ) : null)}
              </ListItem>
              {item.nestedItems && (
                <Collapse
                  in={
                    item.text === "Dashboard"
                      ? dashboardOpen
                      : item.text === "Tutors"
                      ? tutorsOpen
                      : false
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.nestedItems.map((nestedItem, nestedIndex) => (
                      <ListItem
                        button
                        key={nestedIndex}
                        onClick={nestedItem.onClick}
                        className={classes.nested}
                      >
                        <ListItemIcon className={classes.icon}>
                          {nestedItem.icon}
                        </ListItemIcon>
                        <ListItemText primary={nestedItem.text} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))
        ) : (
          <ListItem button onClick={handleBackToStudentClick}>
            <ListItemIcon className={classes.icon}>
              <ArrowBackIcon />
            </ListItemIcon>
            {open && (
              <ListItemText
                primary="Back to Student"
                className={classes.listItemText}
              />
            )}
          </ListItem>
        )}
      </List>
      <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.icon}>
              <ExitToAppIcon />
            </ListItemIcon>
            {open && (
              <ListItemText primary="Logout" className={classes.listItemText} />
            )}
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default SideBar; // Export the SideBar component as default
