import React from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { courses, courses2 } from "../../assets/images/index";

const coursesData = [
  {
    id: 1,
    name: "React for Beginners",
    progress: 75,
    image: courses,
  },
  {
    id: 2,
    name: "Advanced JavaScript",
    progress: 45,
    image: courses2,
  },
  {
    id: 3,
    name: "Python Data Science",
    progress: 90,
    image: courses2,
  },
  {
    id: 4,
    name: "Introduction to Machine Learning",
    progress: 30,
    image: courses,
  },
  {
    id: 5,
    name: "Web Development Bootcamp",
    progress: 60,
    image: courses2,
  },
  {
    id: 6,
    name: "UI/UX Design Fundamentals",
    progress: 50,
    image: courses,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    color: "#BDC4CE", // Neutral text color
  },
  courseCard: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    maxWidth: 300,
    fontFamily: "Poppins",
    backgroundColor: "#1A222C", // Secondary background
    color: "#BDC4CE", // Neutral text color
    borderRadius: theme.spacing(2), // Rounded corners
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)", // Stylish shadow
    transition: "transform 0.3s, box-shadow 0.3s", // Hover effects
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.7)",
    },
  },
  courseImage: {
    width: "100%",
    height: 150,
    objectFit: "cover",
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1.5), // Rounded image corners
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7))", // Gradient overlay
      borderRadius: theme.spacing(1.5),
    },
  },
  progressContainer: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  progressText: {
    marginTop: theme.spacing(0.5),
    textAlign: "right",
    fontFamily: "Poppins",
    color: "#BDC4CE", // Neutral text color
  },
  progressBar: {
    backgroundColor: "#3C50E0", // Primary color for progress bar
    height: 8,
    borderRadius: 4,
  },
  progressTrack: {
    backgroundColor: "#BDC4CE", // Neutral color for track
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "#FFFFFF", // Highlight text color
  },
}));

const CourseCard = ({ course }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.courseCard}>
      <div
        className={classes.courseImage}
        style={{ backgroundImage: `url(${course.image})` }}
      />
      <Typography variant="h6" gutterBottom className={classes.title}>
        {course.name}
      </Typography>
      <Box className={classes.progressContainer}>
        <LinearProgress
          variant="determinate"
          value={course.progress}
          classes={{
            barColorPrimary: classes.progressBar,
            colorPrimary: classes.progressTrack,
          }}
        />
        <Typography className={classes.progressText}>
          {course.progress}%
        </Typography>
      </Box>
    </Paper>
  );
};

const Courses = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography
        variant="h4"
        gutterBottom
        style={{ fontFamily: "Poppins", color: "#FFFFFF" }} // Highlight text color for heading
      >
        Your Courses
      </Typography>
      <Grid container spacing={3}>
        {coursesData.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <CourseCard course={course} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Courses;
