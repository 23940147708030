import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#24303F", // Dark background color
    padding: theme.spacing(2),
  },
  card: {
    width: "100%",
    maxWidth: "600px",
    backgroundColor: "#1A222C", // Secondary background color
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
    position: "relative",
    overflow: "hidden",
    color: "#BDC4CE", // Neutral text color
  },
  content: {
    maxHeight: "400px",
    overflowY: "auto",
    padding: theme.spacing(2),
    border: `1px solid #BDC4CE`, // Neutral border color
    backgroundColor: "#1A222C", // Secondary background color
  },
  message: {
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: (props) =>
      props.role === "user" ? "flex-end" : "flex-start",
  },
  messageText: {
    maxWidth: "70%",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: (props) =>
      props.role === "user" ? "#3C50E0" : "#BDC4CE", // Primary elements color
    color: (props) =>
      props.role === "user" ? "#FFFFFF" : "#000000", // Text color
  },
  typing: {
    maxWidth: "70%",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: "#BDC4CE", // Neutral background color
    color: "#000000", // Text color
  },
  error: {
    color: "#E57373", // Error color
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  micButton: {
    padding: theme.spacing(2),
    borderRadius: "50%",
    color: "#FFFFFF", // White icon color
    backgroundColor: (props) =>
      props.listening ? "#E57373" : "#3C50E0", // Dynamic background color
    "&:hover": {
      backgroundColor: (props) =>
        props.listening ? "#D32F2F" : "#303F9F", // Darker shade on hover
      transform: "scale(1.05)",
      transition: "background-color 0.3s, transform 0.2s",
    },
  },
}));

const VoiceAssistant = () => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("voiceChatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [listening, setListening] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const classes = useStyles({ role: messages.role, listening });

  // Initialize Speech Recognition
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = SpeechRecognition ? new SpeechRecognition() : null;

  useEffect(() => {
    // Save messages to local storage whenever they change
    localStorage.setItem("voiceChatMessages", JSON.stringify(messages));
  }, [messages]);

  const startListening = () => {
    if (!recognition) {
      setError("Speech Recognition API is not supported in this browser.");
      return;
    }
    setError(null);
    recognition.lang = "en-US";
    recognition.start();
    setListening(true);
    console.log("Listening started");
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();
      setListening(false);
      console.log("Listening stopped");
    }
  };

  const handleSendMessage = async (text) => {
    setLoading(true);
    setError(null);

    try {
      console.log("Sending message to API:", text);
      const response = await axios.post(
        "/api/proxy",
        {
          model: "cortext-ultra",
          stream: false,
          top_p: 1,
          temperature: 0.5,
          max_tokens: 150,
          messages: [...messages, { role: "user", content: text }],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_CORCEL_API_KEY}`,
          },
        }
      );

      const botMessageContent = response.data[0].choices[0].delta.content;
      console.log("API Response received:", botMessageContent);

      const botMessage = { role: "assistant", content: botMessageContent };
      setMessages((prevMessages) => [...prevMessages, botMessage]);

      // Text-to-Speech
      const utterance = new SpeechSynthesisUtterance(botMessageContent);
      window.speechSynthesis.speak(utterance);

      utterance.onend = () => {
        console.log("Speech synthesis finished");
        if (listening) {
          console.log("Resuming listening");
          recognition.start();
        }
      };

      utterance.onerror = (event) => {
        console.error("Speech synthesis error:", event.error);
        setError("Error during speech synthesis. Please try again.");
        setLoading(false);
        setListening(false);
      };
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to get a response from the assistant. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (recognition) {
    recognition.onstart = () => {
      console.log("Speech recognition started");
    };

    recognition.onspeechstart = () => {
      console.log("Speech detected");
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log("Speech recognition result received:", transcript);

      const newMessage = { role: "user", content: transcript };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      handleSendMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setError("Error recognizing speech. Please try again.");
      setListening(false);
    };

    recognition.onend = () => {
      console.log("Speech recognition ended");
      if (listening) {
        console.log("Resuming listening after recognition ended");
        recognition.start();
      }
    };
  }

  return (
    <Container className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Voice Assistant
          </Typography>

          <Box className={classes.content}>
            {messages.map((message, index) => (
              <Box
                key={index}
                className={classes.message}
                role={message.role === "user" ? "user" : "assistant"}
              >
                <Box className={classes.messageText}>{message.content}</Box>
              </Box>
            ))}
            {loading && (
              <Box className={classes.typing}>Assistant is typing...</Box>
            )}
          </Box>

          {error && (
            <Typography variant="body1" className={classes.error}>
              {error}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Box display="flex" justifyContent="center" width="100%">
            <Button
              onClick={listening ? stopListening : startListening}
              className={classes.micButton}
            >
              {listening ? <MicOffIcon /> : <MicIcon />}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Container>
  );
};

export default VoiceAssistant;
