import React, { useState } from 'react';
import {
  Container, Stepper, Step, StepLabel, Button, TextField, Typography,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig'; 


const backedURL = " http://localhost:5000/api/v1/tutor/createTutor";



const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    borderRadius: "8px",
    padding : theme.spacing(4),
    backgroundColor: '#f0f0f0'

  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
  },
  stepContent: {
    marginTop: theme.spacing(2),
  },
  stepTitle: {
    marginBottom: theme.spacing(2),
  },
}));

const steps = [
  'About',
  'Photo Upload',
  'Certification',
  'Education',
  'Description',
  'Video',
  'Availability',
  'Pricing',
];

const GetStarted = () => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    fName: '',
    lName: '',
    country: '',
    phone: '',
    altphone: '',
    email: '',
    address: '',
    school: '',
    marks: '',
    description: '',
    availabilityday: '',
    availiblitytime: '',
    pricing: '',
  });
  const [fileUrls, setFileUrls] = useState({
    profileurl: '',
    idurl: '',
    certificationurl: '',
    videourl: '',
  });

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit(); // Call the API on the last step
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const inputName = event.target.name;
  if (file && inputName) {
    try {
      const fileRef = ref(storage, `tutorUploads/${file.name}`);
      await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(fileRef);
      console.log('File available at', downloadURL);

      setFileUrls((prevState) => ({
        ...prevState,
        [inputName]: downloadURL,
      }));
      
      // Handle the download URL, e.g., save it to your state or send it to your backend
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  else{
    console.error("No file selected");
  }
  };

  const handleSubmit = async () => {
    const tutorData = {
      ...formValues,
      ...fileUrls,
    };

    console.log(tutorData);

    

    try {
      const response = await fetch(backedURL,{
        method : 'POST',
        headers: {
          'Content-type' : 'application/json',
        },
        body : JSON.stringify(tutorData),
      }
      );
      console.log(response);

 // has to check the response to get redirected-->(respone.ok)
 //for time being is transferd to dashboard
      if (response.ok) {
        history.push('/teacher-dashboard');
      } else {
        console.error('Error submitting form');
      }

    } catch (error) {
      console.error('Error submitting form (catch)', error);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className={classes.stepContent}>
            <Typography variant="h6" className={classes.stepTitle}>About You</Typography>
            <TextField label="First Name" name="fName" value={formValues.fName} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Last Name" name="lName" value={formValues.lName} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Country Of Birth" name="country" value={formValues.country} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Contact Number" name="phone" value={formValues.phone} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Alternate Contact Number" name="altphone" value={formValues.altphone} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Email" name="email" value={formValues.email} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Permanent Address" name="address" value={formValues.address} onChange={handleChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit & Next</Button>
          </div>
        );
      case 1:
        return (
          <div className={classes.stepContent}>
            <Typography variant="h6" className={classes.stepTitle}>Upload Your Photo and ID Proof</Typography>
            <Typography>Upload your picture and a valid ID proof (Aadhar, Passport, or Driving License).</Typography>
            <Button variant="contained" component="label">Choose Picture
              <input type="file" name="profileurl" onChange={handleFileChange} hidden />
            </Button>
            <Button variant="contained" component="label" style={{ marginLeft: '10px' }}>Choose ID Proof
              <input type="file" name="idurl" onChange={handleFileChange} hidden />
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit & Next</Button>
            <Button onClick={handleBack} className={classes.button}>Back</Button>
          </div>
        );
      case 2:
        return (
          <div className={classes.stepContent}>
            <Typography variant="h6" className={classes.stepTitle}>Certification</Typography>
            <Typography>Upload your certification details and documents.</Typography>
            <TextField label="Certification Details" name="certificationdet" value={formValues.certificationdet} onChange={handleChange} fullWidth margin="normal" />
            <Button variant="contained" component="label">Upload Certificate
              <input type="file" name="certificationurl" onChange={handleFileChange} hidden />
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit & Next</Button>
            <Button onClick={handleBack} className={classes.button}>Back</Button>
          </div>
        );
      case 3:
        return (
          <div className={classes.stepContent}>
            <Typography variant="h6" className={classes.stepTitle}>Education</Typography>
            <Typography>Provide your schooling and degree details.</Typography>
            <TextField label="School Name" name="school" value={formValues.school} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Board of Study" name="boardOfStudy" value={formValues.boardOfStudy} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Year of Passing" name="yearOfPassing" value={formValues.yearOfPassing} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Class Percentage" name="marks" value={formValues.marks} onChange={handleChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit & Next</Button>
            <Button onClick={handleBack} className={classes.button}>Back</Button>
          </div>
        );
      case 4:
        return (
          <div className={classes.stepContent}>
                       <Typography variant="h6" className={classes.stepTitle}>Description</Typography>
            <Typography>Provide a brief description of your teaching experience and skills.</Typography>
            <TextField label="Description" name="description" value={formValues.description} onChange={handleChange} fullWidth margin="normal" multiline rows={4} />
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit & Next</Button>
            <Button onClick={handleBack} className={classes.button}>Back</Button>
          </div>
        );
      case 5:
        return (
          <div className={classes.stepContent}>
            <Typography variant="h6" className={classes.stepTitle}>Video</Typography>
            <Typography>Upload a short introduction video.</Typography>
            <Button variant="contained" component="label">Upload Video
              <input type="file" name="videourl" onChange={handleFileChange} hidden />
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit & Next</Button>
            <Button onClick={handleBack} className={classes.button}>Back</Button>
          </div>
        );
      case 6:
        return (
          <div className={classes.stepContent}>
            <Typography variant="h6" className={classes.stepTitle}>Availability</Typography>
            <Typography>Set your availability for tutoring.</Typography>
            <TextField label="Available Days" name="availabilityday" value={formValues.availabilityday} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Available Times" name="availiblitytime" value={formValues.availiblitytime} onChange={handleChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit & Next</Button>
            <Button onClick={handleBack} className={classes.button}>Back</Button>
          </div>
        );
      case 7:
        return (
          <div className={classes.stepContent}>
            <Typography variant="h6" className={classes.stepTitle}>Pricing</Typography>
            <Typography>Set your pricing details for the tutoring sessions.</Typography>
            <TextField label="Pricing Per Hour" name="pricing" value={formValues.pricing} onChange={handleChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>Submit</Button>
            <Button onClick={handleBack} className={classes.button}>Back</Button>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center" gutterBottom>Get Started as a Tutor</Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
      </Paper>
    </Container>
  );
};

export default GetStarted;

