import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Area, AreaChart, CartesianGrid } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#1A222C', // Dark theme background
    color: '#BDC4CE', // Light text color
    borderRadius: '8px',
  },
  graphContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    overflowX: 'auto',
  },
  slider: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  areaText: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    color: '#BDC4CE', // Light text color
    fontSize: '18px',
  },
  chartTitle: {
    color: '#3C50E0', // Primary color for headings
    fontFamily: 'Poppins',
  },
  chart: {
    borderRadius: '8px',
    backgroundColor: '#24303F', // Dark background for the chart area
    padding: theme.spacing(2),
  },
}));

const data = [
  { name: '0', words: 0 },
  { name: '10', words: 50 },
  { name: '20', words: 75 },
  { name: '30', words: 80 },
  { name: '40', words: 85 },
  { name: '50', words: 90 },
  { name: '60', words: 95 },
  { name: '70', words: 97 },
  { name: '80', words: 98 },
  { name: '90', words: 99 },
  { name: '100', words: 100 },
];

const Vocabulary = () => {
  const classes = useStyles();
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const filteredData = data.filter((entry) => parseInt(entry.name) <= sliderValue);

  // Calculate the area under the graph for the highlighted portion
  const calculateArea = (data) => {
    let area = 0;
    for (let i = 0; i < data.length - 1; i++) {
      const width = data[i + 1].name - data[i].name;
      const height = (data[i].words + data[i + 1].words) / 2;
      area += width * height;
    }
    return area;
  };

  const areaUnderGraph = calculateArea(filteredData);

  return (
    <Container className={classes.root}>
      <Typography variant="h5" gutterBottom className={classes.chartTitle}>
        Learn what matters most with 
        <span style={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#3C50E0' }}> Explified </span>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
      </Typography>
      <Box className={classes.graphContainer}>
        <AreaChart
          width={800}
          height={400}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          className={classes.chart}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#BDC4CE" />
          <XAxis dataKey="name" stroke="#BDC4CE" />
          <YAxis stroke="#BDC4CE" />
          <Tooltip />
          <Area type="monotone" dataKey="words" stroke="#3C50E0" fill="#3C50E0" />
        </AreaChart>
      </Box>
      <Typography variant="body1" className={classes.areaText}>
        Number of words learnt in {sliderValue} days: {areaUnderGraph.toFixed(2)}
      </Typography>
      <div className={classes.slider}>
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          step={10}
          marks
          min={0}
          max={100}
          valueLabelDisplay="auto"
        />
      </div>
    </Container>
  );
};

export default Vocabulary;
