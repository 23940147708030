import React from "react";
import { Container, Grid, Typography, Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LanguageIcon from "@material-ui/icons/Language";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    fontFamily: "'Poppins', sans-serif",
    padding: theme.spacing(7, 1),
    backgroundImage:
      "linear-gradient(to bottom, #191919, #151515, #0f0f0f, #080808, #000000)",
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: "rgb(223, 211, 211)",
    fontSize: "1.25rem", // Increased font size
    fontWeight: "500",
    "&:hover": {
      color: "#ffffff",
    },
  },
  socialIcon: {
    color: "white",
    marginRight: theme.spacing(1),
    "&:hover": {
      color: "#3C50E0",
    },
  },
  socialText: {
    fontSize: "1.25rem", // Increased font size for social text
    color: "rgb(223, 211, 211)",
    fontWeight: "500",
    marginLeft: theme.spacing(1),
  },
  footerText: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "1.25rem", // Increased footer text size
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  logoText: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "1.75rem", // Larger logo text size
    textAlign: "center",
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <Box component="footer" className={classes.footer}>
      <Container>
        <Grid container spacing={4} justify="center">
          {/* Links */}
          <Grid item xs={12} md={3} container direction="column" justifyContent="space-evenly">
            <Box display="flex" justifyContent="center">
              <Link to="/disclaimer" className={classes.link}>
                Disclaimer
              </Link>
            </Box>
            <Box display="flex" justifyContent="center">
              <Link to="/privacy-policy" className={classes.link}>
                Privacy Policy
              </Link>
            </Box>
            <Box display="flex" justifyContent="center">
              <Link to="/terms-of-service" className={classes.link}>
                Terms of Service
              </Link>
            </Box>
          </Grid>

          {/* Social */}
          <Grid item xs={12} md={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton
                component="a"
                href="https://www.youtube.com/@Explified"
                target="_blank"
                className={classes.socialIcon}
              >
                <YouTubeIcon fontSize="large" />
              </IconButton>
              <Typography className={classes.socialText}>YouTube</Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
              <IconButton
                component="a"
                href="https://web.explified.com"
                target="_blank"
                className={classes.socialIcon}
              >
                <LanguageIcon fontSize="large" />
              </IconButton>
              <Typography className={classes.socialText}>Explified Explore</Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Logo and footer text */}
        <Typography variant="h4" className={classes.logoText}>
          Explified
        </Typography>
        <Typography className={classes.footerText}>
          © {new Date().getFullYear()} Explified. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
