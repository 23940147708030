import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Define styles for the component using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "rgba(19,20,21)",
    backgroundColor: "rgb(8,8,8)",
    color: "#fff",
    fontFamily: "Poppins",
  },
  title: {
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "4rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  [theme.breakpoints.down("sm")]: {
    title: {
      fontSize: "2.5rem",
    },
  },
}));

const features = [
  {
    alt: "Core Lessons",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Core_Lessons_1c7ff74a59_d77a621881.png",
    title: "Core Lessons",
    description:
      "Compact 30-minute sessions you can complete on the go, or at home using Alexa.",
  },
  {
    alt: "Reading Lessons",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Reading_Lessons_c03e63fe42_efec1f5c49.png",
    title: "Reading Lessons",
    description: "Master reading in a new language with ease.",
  },
  {
    alt: "AI Voice Coach",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Voice_Coach_7264eaf8c3_6219a5b87a.png",
    title: "AI Voice Coach",
    description:
      "Advanced AI provides real-time feedback on your pronunciation.",
  },
  {
    alt: "Digital Flash Cards",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Flash_cards_dc88848eeb_f9eadd67e3.png",
    title: "Digital Flash Cards",
    description:
      "Enhance your vocabulary and explore written language in depth with flashcards.",
  },
  {
    alt: "Speak Easy Challenge",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Speak_Easy_Challenge_ea21182a41_410b8180f5.png",
    title: "Speak Easy Challenge",
    description:
      "Engage in role-play exercises while reviewing detailed conversation transcripts.",
  },
  {
    alt: "Speed Round Game",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Speed_Round_b0a01690c7_afdc1399d5.png",
    title: "Speed Round Game",
    description:
      "Challenge your conversational skills with a fast-paced and engaging game.",
  },
  {
    alt: "Build your Skills",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Build_Your_Skills_7fa91fc2ef_24c81ef23f.png",
    title: "Build your Skills",
    description:
      "Unlock new badges with each lesson and practice essential phrases.",
  },
  {
    alt: "Uninterrupted Learning",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Download_ce35586893_da73dfd395.png",
    title: "Uninterrupted Learning",
    description:
      "Stream live or download lessons for offline use with the language app.",
  },
  {
    alt: "Lightbulb Moments",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Lightbulb_moments_2ce351faef_a507324aaa.png",
    title: "Lightbulb Moments",
    description:
      "Link cultural and historical insights to your language learning journey.",
  },
  {
    alt: "Build Your Streak",
    src: "https://d1enwirvb3djmg.cloudfront.net/Property_1_Streak_8e1caeab41_3c74070c48.png",
    title: "Build Your Streak",
    description:
      "Set daily goals, receive reminders, and boost your learning streak by staying consistent.",
  },
];
const FeatureSection = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box pt={8} pb={8} color="textPrimary">
        <Typography gutterBottom className={classes.title}>
          Get Conversational Quickly with Explified
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ marginTop: 16, fontFamily: "Poppins" }}
        >
          Learn languages online with the simplicity and engagement of popular
          language learning apps, combined with the effectiveness and
          portability of the Explified Method™. It's a winning combination that
          makes language learning easier than ever.
        </Typography>

        <Grid container spacing={3} justify="center" style={{ marginTop: 16 }}>
          {features.map((feature, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              style={{ textAlign: "center" }}
            >
              <img
                src={feature.src}
                alt={feature.alt}
                style={{ width: 80, height: 80, borderRadius: "50%" }}
              />
              <Typography
                variant="h6"
                style={{
                  marginTop: 8,
                  fontWeight: "500",
                  fontFamily: "Poppins",
                }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body2"
                style={{ marginTop: 4, fontFamily: "Poppins" }}
              >
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default FeatureSection;
