import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ChatBot.css";
import * as images from "../../assets/images";
import SendIcon from '@material-ui/icons/Send';
import LoopIcon from '@material-ui/icons/Loop';

const ChatBot = ({handleTyping , setIsTyping , isTyping}) => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("chatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages));
  }, [,messages]);

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const newMessage = { role: "user", content: input };
    setMessages([...messages, newMessage]);
    console.log("Chat History before sending:", messages);
    setInput("");
    setLoading(true);

    try {
      const response = await axios.post(
        "/api/proxy",
        {
          model: "cortext-ultra",
          stream: false,
          top_p: 1,
          temperature: 0.0001,
          max_tokens: 4096,
          messages: [...messages, newMessage],
        },
        {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
          },
        }
      );
      console.log(response);

      console.log("Cortex response:", response);
      console.log("Cortex response data:", response.data);
      console.log(
        "Chatbot response:",
        response.data[0].choices[0].delta.content
      );
      const botMessage = {
        role: "assistant",
        content: response.data[0].choices[0].delta.content,
      };
      setMessages([...messages, newMessage, botMessage]);
      console.log("Chat History after sending:", messages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

const onXClick = () => {
  setIsTyping(!isTyping);
  setMessages([]);
}

   

  return (
    <div className="chat-container">
     {/* Clear Chat Button */}
    <button className="clear-chat-button" onClick={onXClick}>
      ×
    </button>
      <div className="chat-box" style= {{height : isTyping ? "60vh" : "100%" }}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            <div className="message-content">{message.content}</div>
            <div className="sender-image">
              <img src={images.sender} className="send-img" alt="Sender's Image" />
            </div>
          </div>
        ))}
      </div>
      
      <div className="input-container">
        <input
          type="text"
          className="chat-input"
          placeholder="Type your message..."
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onFocus={handleTyping}
        />
        <button
          className="send-button"
          onClick={handleSendMessage}
          disabled={loading}
        >
          {loading ? <LoopIcon /> : <SendIcon />}
        </button>
      </div>
    </div>
  );
};

export default ChatBot;