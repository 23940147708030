import React from 'react';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
  },
  step: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
      width: '100%',
      height: 'auto',
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: '0.875rem',
  },
}));

const ReferJourney = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2} justify="center" alignItems="stretch">
        {/* Step 1 */}
        <Grid item xs={12} sm={4}>
          <Paper className={classes.step} elevation={3}>
            <Typography variant="h6" className={classes.title}>
              Invite a Friend
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Share the app link with your friends and family to get them started on this exciting journey!
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Every invitation brings you closer to unlocking new rewards and features. Let’s grow together!
            </Typography>
          </Paper>
        </Grid>

        {/* Step 2 */}
        <Grid item xs={12} sm={4}>
          <Paper className={classes.step} elevation={3}>
            <Typography variant="h6" className={classes.title}>
              Share the Joy
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Experience the benefits together and strengthen your friendships through shared rewards.
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Your friends receive exclusive perks, and you enjoy bonuses for every friend who joins.
            </Typography>
          </Paper>
        </Grid>

        {/* Step 3 */}
        <Grid item xs={12} sm={4}>
          <Paper className={classes.step} elevation={3}>
            <Typography variant="h6" className={classes.title}>
              Explore More
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Unlock new features and discover more exciting opportunities as you invite more friends.
            </Typography>
            <Typography variant="body2" className={classes.description}>
              With each referral, gain access to premium content and special rewards. Keep exploring!
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReferJourney;
