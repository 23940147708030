import React from "react";
import {
  Box,
  Typography,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  progressBarContainer: {
    backgroundColor: "#1A222C", // Secondary background color
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    color: "#BDC4CE", // Text color
  },
  scoreContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
  },
  progressContainer: {
    flex: 3,
    position: "relative",
  },
  progressBar: {
    height: "8px",
    borderRadius: "4px",
    backgroundColor: "#555", // Darker grey background for the progress bar
  },
  milestonesContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  gradeContainer: {
    position: "absolute",
    top: "-25px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  scoreText: {
    marginBottom: "10px",
  },
  whiteBar: {
    width: "2px",
    height: "120px",
    borderRadius: "4px",
    backgroundColor: "#BDC4CE", // Text color
  },
}));

const ProgressBar = ({ score, grade }) => {
  const classes = useStyles();

  return (
    <Box className={classes.progressBarContainer}>
      <Box className={classes.scoreContainer}>
        <Typography variant="h6" className={classes.scoreText}>
          Your Score
        </Typography>
        <Typography variant="h4">{score}</Typography>
        <Typography variant="h6">
          Advanced <span style={{ color: "#4caf50" }}>{grade}</span> {/* Primary color */}
        </Typography>
      </Box>
      <span className={classes.whiteBar}></span>
      <Box className={classes.progressContainer}>
        <Box className={classes.gradeContainer}>
          <Typography variant="body2">A1</Typography>
          <Typography variant="body2">A2</Typography>
          <Typography variant="body2">B1</Typography>
          <Typography variant="body2">B2</Typography>
          <Typography variant="body2">{grade}</Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={score}
          className={classes.progressBar}
        />
        <Box className={classes.milestonesContainer}>
          <Typography variant="body2">Beginner</Typography>
          <Typography variant="body2">Intermediate</Typography>
          <Typography variant="body2">Advanced</Typography>
          <Typography variant="body2">Expert</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBar;
