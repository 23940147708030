import React, { useEffect, useRef, useState } from "react";
import Typed from "typed.js";
import {
  Box,
  Button,
  Typography,
  Paper,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import LoginPopup from "./LoginPopup";
import { FaArrowRight } from "react-icons/fa";
import * as images from "../../assets/images";
import Games from "../home/Games";
import ParallaxSection from "./ParallaxSection";
import { useHistory } from "react-router-dom";
import GetStartedUser from "./GetStartedUser";
import FeatureSection from "./FeaturesSection";
import CardsSection from "./CardSection";
import Features from "./Features";
import FeaturedVideo from "./FeaturedVideo";
import VideoGallery from "./VideoGallery";

// Style sheet hook (CSS) for the components using Material UI v4
const useStyles = makeStyles((theme) => ({
  ctaGradientBackground: {
    // background: "linear-gradient(90deg, #23b5b5, #2563eb)", // Gradient background for CTA section
    backgroundColor: "#000000",
    padding: theme.spacing(5), // Padding
  },
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column", // Default to column direction
    gap: "10px", // Gap between items
    [theme.breakpoints.down("sm")]: {
      gap: "5px", // Reduce gap on small screens for better spacing
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row", // Change to row direction on medium and larger screens
      gap: "15px", // Increase gap on medium screens
    },
    [theme.breakpoints.up("lg")]: {
      gap: "20px", // Further increase gap on large screens
    },
  },
  downloadImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingInline: "2px", // Padding on both sides
    [theme.breakpoints.down("sm")]: {
      paddingInline: "1px", // Reduce padding on smaller screens
      flexDirection: "column", // Stack items vertically on small screens if needed
    },
    [theme.breakpoints.up("md")]: {
      paddingInline: "5px", // Increase padding slightly on medium screens
    },
    [theme.breakpoints.up("lg")]: {
      paddingInline: "10px", // Increase padding on large screens
    },
  },
  downloadImage: {
    padding: "2px", // Padding around the image
    width: "auto",
    height: "auto",
    cursor: "pointer", // Pointer cursor on hover
    transition: "transform 0.3s", // Smooth transform transition
    "&:hover": {
      transform: "scale(1.1)", // Scale image on hover
    },
  },
  container: {
    minHeight: "480px", // Minimum height for container
    // backgroundColor: "#2563eb", // Background color
    backgroundColor: "#0B0C14",
    color: "white", // Text color
    position: "relative",
    padding: "125px", // Padding
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6), // Reduce padding on small screens
      // margin: theme.spacing(3,0),
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(3),
    },
  },
  content: {
    width: "100%", // Full width
    display: "flex", // Flex display
    flexDirection: "column", // Column direction
    alignItems: "flex-start", // Align items to start
    gap: theme.spacing(2), // Gap between items
    marginTop: theme.spacing(5), // Margin top
    [theme.breakpoints.up("md")]: {
      width: "70%", // Width for medium and larger screens
    },
  },
  heading: {
    fontSize: "2.5rem", // Font size
    fontWeight: "normal", // Font weight
    fontFamily: "Poppins", // Font family
    padding: theme.spacing(0, 0), // Padding
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem", // Font size for medium and larger screens
      marginBottom: theme.spacing(2, 3), // Margin bottom
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem", // Font size for small screens
    },
  },
  runningHeading: {
    height: "180px", // Height
    fontSize: "2.5rem", // Font size
    fontWeight: "bold", // Bold font weight
    fontFamily: "Poppins", // Font family
    padding: theme.spacing(1, 0), // Padding
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem", // Font size for medium and larger screens
      height: "150px", // Height
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem", // Font size for small screens
    },
  },
  description: {
    marginBottom: theme.spacing(1), // Margin bottom
    fontFamily: "Poppins", // Font family
  },
  button: {
    backgroundColor: "white", // Background color
    color: "black", // Text color
    fontWeight: "bold", // Bold font weight
    fontFamily: "Poppins", // Font family
    padding: theme.spacing(1.5, 3), // Padding
    borderRadius: "4px", // Border radius
    boxShadow: theme.shadows[2], // Box shadow
    display: "flex", // Flex display
    alignItems: "center", // Align items center
    gap: theme.spacing(1), // Gap between items
    "&:hover": {
      transform: "scale(1.1)", // Scale on hover
      backgroundColor: "#f1f1f1", // Background color on hover
    },
  },
  formButton: {
    marginTop: theme.spacing(2), // Margin top
    backgroundColor: "#2563eb", // Background color
    color: "#fff", // Text color
    fontFamily: "Poppins", // Font family
    cursor: "pointer", // Pointer cursor
    padding: "12px 16px", // Padding
    "&:hover": {
      backgroundColor: "#1d4ed8", // Background color on hover
    },
  },
  textField: {
    "& .MuiInputBase-root": {
      fontFamily: "Poppins", // Font family
      height: "100%", // Full height
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Poppins", // Font family
    },
    margin: theme.spacing(1, 0), // Margin
  },
  section: {
    paddingInline: theme.spacing(3), // Padding on both sides
    display: "flex", // Flex display
    flexDirection: "column", // Column direction
    backgroundColor: "#fff", // Background color
    gap: theme.spacing(4), // Gap between items
  },
  sectionContainer: {
    display: "flex", // Flex display
    alignItems: "center", // Align items center
    justifyContent: "center", // Center content
    paddingInline: "125px", // Padding on both sides
    marginTop: "20px", // Margin top
    [theme.breakpoints.down("md")]: {
      flexDirection: "column", // Stack items vertically on small screens
      paddingInline: theme.spacing(2), // Padding on both sides for small screens
      gap: theme.spacing(3), // Gap between items
    },
  },
  micIcon: {
    fontSize: "2rem", // Font size
    color: "#2563eb", // Icon color
    display: "flex", // Flex display
    justifyContent: "center", // Center content
    textAlign: "center", // Text align center
    alignItems: "center", // Align items center
    margin: "0 auto", // Margin
    marginBottom: "35px", // Margin bottom
    animation: "$bounce 2s infinite", // Bounce animation
    fontFamily: "Poppins", // Font family
  },
  "@keyframes bounce": {
    "0%, 20%, 50%, 80%, 100%": {
      transform: "translateY(0)", // Initial and end position
    },
    "40%": {
      transform: "translateY(-30px)", // Upward bounce
    },
    "60%": {
      transform: "translateY(-15px)", // Downward bounce
    },
  },
  sectionTitle: {
    fontFamily: "Poppins, sans-serif", // Font family
    fontWeight: "bold", // Bold font weight
    color: "#2563eb", // Text color
    marginTop: theme.spacing(2), // Margin top
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // Font size for small screens
    },
  },
  sectionText: {
    fontFamily: "Poppins", // Font family
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem", // Font size for small screens
    },
  },
  image: {
    justifyContent: "center", // Center content
    width: "75%", // Width
    minHeight: 50, // Minimum height
    maxHeight: "50%", // Maximum height
    objectFit: "fill", // Object fit
    marginBottom: "15px", // Margin bottom
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Width for small screens
      maxHeight: "100%", // Maximum height for small screens
    },
  },
  contentBox: {
    display: "flex", // Flex display
    flexDirection: "column", // Column direction
    alignItems: "center", // Align items center
    padding: theme.spacing(4), // Padding
    backgroundColor: "#fff", // Background color
    gap: theme.spacing(2), // Gap between items
    boxShadow: theme.shadows[2], // Box shadow
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Stack items vertically on small screens
      padding: theme.spacing(2), // Padding for small screens
    },
  },
  getStartedTextField: {
    width: "100%", // Full width
    marginBottom: theme.spacing(2), // Margin bottom
    "& .MuiInputBase-root": {
      fontFamily: "Poppins", // Font family
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Poppins", // Font family
    },
  },
}));

function Home() {
  const classes = useStyles();
  const [open, setOpen] = useState(false); // State for controlling popup visibility
  const history = useHistory();

  const token = localStorage.getItem("authtoken"); //localStrorage Access

  // open the popup
  const handleOpen = () => {
    setOpen(true);
  };

  //close the popup
  const handleclose = () => {
    setOpen(false);
  };

  const el = useRef(null);

  // typing effect on the home Screen
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Speak with Confidence",
        "Learn Through Real Scenarios",
        "Achieve Fluency with AI & Tutors",
      ],
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 2000,
      loop: true,
    });
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Typography
            component="div"
            fontFamily="Poppins"
            className={classes.heading}
          >
            Unlock your dream language{" "}
          </Typography>
          <Typography
            component="div"
            fontFamily="Poppins"
            className={classes.runningHeading}
          >
            <span ref={el}></span>
          </Typography>
          <Typography className={classes.description}>
            Boost your language skills with hands-on practice and smart AI
            guidance.
          </Typography>
          {/* {
            //checking if the user is authenticated at the begining or after logging out.
            token ? (
                <Button
                  variant="contained"
                  className={classes.button}
                  endIcon={<FaArrowRight/>}
                  onClick={() => history.push("/dashboard")}
                >
                  
                     GO TO DASHBOARD
                  
                </Button>

            )

            :(

            <Button
              variant="contained"
              className={classes.button}
              endIcon={<FaArrowRight/>}
              onClick={handleOpen }
            >
              
                 GET STARTED NOW
              
            </Button>
           
            )
          } */}
          {/* Demo Button */}
          <Button
            variant="contained"
            className={classes.button}
            endIcon={<FaArrowRight />}
            onClick={handleOpen}
          >
            See the Demo (Guest)
          </Button>
        </Box>
      </Box>

      {/* Exams sections  */}
      <Box>
        <ParallaxSection />
      </Box>

       {/* Features */}
       <Features />

      {/* Card Section */}
      {/* <CardsSection /> */}

      {/* Videos */}
      <FeaturedVideo/>
      {/* <VideoGallery/> */}

      {/* Download Apps Section  */}
      <div className={classes.ctaGradientBackground}>
        <Grid container spacing={3} className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.sectionContainer}>
            <Grid>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.micIcon}
                style={{ fontWeight: "bold", color: "white" }}
              >
                Begin your language learning journey by downloading our app now!
              </Typography>

              <div className={classes.root}>
                {/* have to add the deployed appls link of the playstore and app store below */}
                <Grid container spacing={16}>
                  <Grid item xs={6} className={classes.downloadImageContainer}>
                    <a
                      href="https://play.google.com/store"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://static.wixstatic.com/media/7c42a0_1492a6941cb84052860c4203e92ea75c~mv2.png/v1/fill/w_169,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Google%20play.png"
                        alt="playStore"
                        className={classes.downloadImage}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={6} className={classes.downloadImageContainer}>
                    <a
                      href="https://www.apple.com/in/app-store/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://static.wixstatic.com/media/7c42a0_96deac31051d45518eb226bdc1bb2931~mv2.png/v1/fill/w_172,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/app%20store.png"
                        alt="appStore"
                        className={classes.downloadImage}
                      />
                    </a>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Popup for the first user expirence is called  */}
        <GetStartedUser open={open} onClose={handleclose} />
      </div>


      {/* Personalised Section  */}
      {/* 
      <div className={classes.section}>
         <Grid container spacing={4} className={classes.sectionContainer}>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h3"
              gutterBottom
              className={classes.sectionTitle}
            >
              Book Experts
            </Typography>
            <Typography
              variant="body1"
              color="black"
              paragraph
              className={classes.sectionText}
            >
              Connect with seasoned language experts who provide personalized
              guidance and insights to accelerate your learning journey
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src={images.personalisedimg}
              alt="random"
              className={classes.image}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} className={classes.sectionContainer}>
          <Grid item xs={12} md={5}>
            <img src={images.faq} alt="random" className={classes.image} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h3"
              gutterBottom
              className={classes.sectionTitle}
            >
              AI chatbot
            </Typography>
            <Typography
              variant="body1"
              color="black"
              paragraph
              className={classes.sectionText}
            >
              Engage in dynamic conversations with AI, offering instant feedback
              and support to refine your language skills.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} className={classes.sectionContainer}>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h3"
              gutterBottom
              className={classes.sectionTitle}
            >
              Videos
            </Typography>
            <Typography
              variant="body1"
              color="black"
              paragraph
              className={classes.sectionText}
            >
              Immerse yourself in real-life scenarios of your favourite domain
              through videos that enhance your understanding and application of
              the language.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={images.vdo} alt="random" className={classes.image} />
          </Grid>
        </Grid>
      </div> */}

      {/* Explified Games Coming soon component */}
      {/* <Games className={classes.container} /> */}

      {/* Feature Section */}
      {/* <FeatureSection /> */}
    </div>
  );
}

export default Home;
