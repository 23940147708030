import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import ProgressBar from "./ProgressBar";
import GraphAndTasks from "./AnalysisDetails";
import FactorRow from "./FactorRow";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  factorRowContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: '#24303F', // Main background color
  },
}));

const LanguageTestAnalysis = ({ language, data, test }) => {
  const classes = useStyles();

  const progressValues = [data];

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      <Typography style={{ color: "#BDC4CE" }}>{language}</Typography>
      <Grid container style={{ width: "100%" }}>
        <Grid className={classes.factorRowContainer} item xs={12} style={{ marginTop: "10px" }}>
          <FactorRow skills={progressValues.map(item => item.skills)} />
        </Grid>
        <Grid item xs={12}>
          <ProgressBar score={85} grade="C1" />
        </Grid>
        <Grid item xs={12}>
          <GraphAndTasks language={language} data={progressValues} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LanguageTestAnalysis;
