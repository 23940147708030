//Games Section rendered on the home page. of the web App, Coming Soon.
// additon of the OG links on click is yet to be done, after the deployment of the games.

import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as images from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: "linear-gradient(90deg, #23b5b5, #2563eb)",
    backgroundColor: "#000",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: "auto",
    },
  },
  gridContainer: {
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  typography: {
    fontFamily: "Poppins",
    margin: theme.spacing(4,0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      paddingInline: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  },
  imageButton: {
    position: "relative",
    borderRadius: "16px",
    margin: theme.spacing(1),
    width: "100%",
    height: "auto",
    maxWidth: "150px",
    maxHeight: "150px",
    overflow: "hidden",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "120px",
      maxHeight: "120px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "240px",
      maxHeight: "240px",
    },
    "& img": {
      width: "100%",
      height: "100%",
      transition: "transform 0.3s ease-in-out, filter 0.3s ease-in-out",
    },
    "&:hover img": {
      transform: "scale(1.1)",
      filter: "blur(4px)", // Apply blur on hover
      // border: "1px solid #fff",
    },
    "&:hover $overlay": {
      opacity: 1,
      // border: "1px solid #fff",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    opacity: 0, // Initially hidden
    transition: "opacity 0.3s ease-in-out",
    pointerEvents: "none", // Ensure overlay text doesn't affect button clicks
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

const Games = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Typography
          variant="h4"
          className={classes.typography}
          style={{
            color: "white",
            fontFamily: "Poppins",
            fontWeight: "bold",
          }}
        >
          Discover the magic of our games and be a part of the excitement
        </Typography>
        <Grid item container spacing={2} justifyContent="space-around">
          {/* 1st */}
          <Grid item>
            <Button
              className={classes.imageButton}
              href="https://example.com/game1"
            >
              <img src={images.game1} alt="Game 1" />
              <div
                className={classes.overlay}
                style={{ border: "4px solid #255A4A" }}
              >
                Coming Soon
              </div>
            </Button>
          </Grid>

          {/* 2nd */}
          <Grid item>
            <Button
              className={classes.imageButton}
              href="https://example.com/game2"
            >
              <img src={images.game2} alt="Game 2" />
              <div
                className={classes.overlay}
                style={{ border: "4px solid #6F4133" }}
              >
                Coming Soon
              </div>
            </Button>
          </Grid>

          {/* 3rd */}
          <Grid item>
            <Button
              className={classes.imageButton}
              href="https://example.com/game3"
            >
              <img src={images.game3} alt="Game 3" />
              <div
                className={classes.overlay}
                style={{ border: "4px solid #1B405E" }}
              >
                Coming Soon
              </div>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Games;
