import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Avatar,
  TextField,
  IconButton,
  Grid,
  Box,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import userImage from "../../assets/images/teacher.webp"; // Ensure the path is correct

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileImage: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
    height: 200,
    width: 200,
  },
  paper: {
    padding: theme.spacing(3),
    width: "100%",
    maxWidth: 600,
    marginBottom: theme.spacing(2),
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  textField: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
  },
  section: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: "#23b5b5",
    color: "white",
    '&:hover': {
      backgroundColor: "#1a9090",
    }
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const ManageAccount = ({ onClose }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState({
    firstName: false,
    lastName: false,
    email: false,
    contactNumber: false,
    countryOfBirth: false,
  });
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    countryOfBirth: "",
  });

  useEffect(() => {
    // Load profile data from local storage
    const storedProfile = JSON.parse(localStorage.getItem("userProfile"));
    if (storedProfile) {
      setProfile(storedProfile);
    }
  }, []);

  const handleEditClick = (field) => {
    setIsEditing((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSaveChanges = () => {
    // Save updated profile to local storage
    localStorage.setItem("userProfile", JSON.stringify(profile));
    // Optionally, you can close the dialog or give feedback to the user here
    onClose();
  };

  return (
    <Container className={classes.container}>
      <Avatar
        className={classes.profileImage}
        src={userImage}
        alt="Profile Image"
      />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {["firstName", "lastName", "email", "contactNumber", "countryOfBirth"].map((field) => (
            <Grid item xs={12} key={field} className={classes.fieldContainer}>
              <TextField
                className={classes.textField}
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={profile[field]}
                onChange={handleInputChange}
                disabled={!isEditing[field]}
                variant="outlined"
                fullWidth
              />
              <IconButton
                className={classes.iconButton}
                onClick={() => handleEditClick(field)}
              >
                {isEditing[field] ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </Grid>
          ))}
        </Grid>
        <Divider className={classes.divider} />
        <Box className={classes.section}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleSaveChanges}
          >
            Save Changes
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ManageAccount;
