import React, { useEffect, useState } from "react";
import {
  TextField,
  IconButton,
  Typography,
  Button,
  Divider,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  AttachFile as AttachFileIcon,
  Add as AddIcon,
  Comment as CommentIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Add as AddNoteIcon,
} from "@material-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#24303F",
    color: "#BDC4CE",
    minHeight: "100vh",
    padding: theme.spacing(3),
    fontFamily: "Poppins",
  },
  header: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    backgroundColor: "#1A222C",
    color: "#BDC4CE",
    borderRadius: "8px",
    border: "1px solid #BDC4CE", // Add border style here
  },
  noteTitle: {
    color: "#BDC4CE",
    fontFamily: "Poppins",
  },
  noteTitleField: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#2D2D2D",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiInputLabel-outlined": {
      color: "#BDC4CE",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#BDC4CE",
    },
    "& .MuiInputBase-input": {
      color: "#BDC4CE",
    },
  },
  noteContent: {
    color: "#BDC4CE",
    fontFamily: "Poppins",
  },
  commentField: {
    marginTop: theme.spacing(2),
    backgroundColor: "#2D2D2D",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiInputLabel-outlined": {
      color: "#BDC4CE",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#BDC4CE",
    },
    "& .MuiInputBase-input": {
      color: "#BDC4CE",
    },
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: "#3C50E0",
    color: "#FFFFFF",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#2a3c8f",
    },
  },
  subNoteButton: {
    marginTop: theme.spacing(1),
    color: "#3C50E0",
  },
  iconButton: {
    color: "#BDC4CE",
  },
  image: {
    maxWidth: "100%",
    marginTop: theme.spacing(2),
  },
  notesContainer: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: "8px",
    border: "1px solid #BDC4CE", // Add border style here
  },
}));

const dummyNotes = [
  {
    id: 1,
    title: "Meeting Notes",
    content: "<p>Discuss project milestones and deadlines.</p><p>Prepare presentation slides.</p>",
    comment: "Follow up with team members.",
    image: "https://via.placeholder.com/300",
    subnotes: ["Project timeline", "Slide design", "Team feedback"],
  },
  {
    id: 2,
    title: "Grocery List",
    content: "<p>Buy groceries for the week.</p><p>Include fruits, vegetables, and dairy products.</p>",
    comment: "Check for any ongoing discounts.",
    image: "https://via.placeholder.com/300",
    subnotes: ["Apples", "Bananas", "Milk", "Bread"],
  },
  {
    id: 3,
    title: "Travel Itinerary",
    content: "<p>Book flights and accommodations.</p><p>Prepare travel documents and itinerary.</p>",
    comment: "Ensure all bookings are confirmed.",
    image: "https://via.placeholder.com/300",
    subnotes: ["Flight tickets", "Hotel reservation", "Rental car"],
  },
  {
    id: 4,
    title: "Workout Plan",
    content: "<p>Plan daily workout routines.</p><p>Include strength training and cardio exercises.</p>",
    comment: "Track progress and adjust routines as needed.",
    image: "https://via.placeholder.com/300",
    subnotes: ["Morning runs", "Evening gym sessions", "Stretching exercises"],
  },
  {
    id: 5,
    title: "Book Reading List",
    content: "<p>List of books to read this month.</p><p>Include a mix of fiction and non-fiction.</p>",
    comment: "Add new releases to the list.",
    image: "https://via.placeholder.com/300",
    subnotes: ["The Great Gatsby", "Sapiens", "Educated", "Becoming"],
  },
];


const Notes = () => {
  const classes = useStyles();
  const [notes, setNotes] = useState(dummyNotes);
  const [newNoteTitle, setNewNoteTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [comment, setComment] = useState("");
  const [image, setImage] = useState(null);
  const [link, setLink] = useState("");
  const [subNotes, setSubNotes] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingNoteId, setEditingNoteId] = useState(null); // Track the note ID being edited
  const API_URL = "https://explified-backend.web.app/api/v1/notes";

  // Fetching notes from backend API
  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${API_URL}/getNotes`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        setNotes(response.data);
      } else {
        console.error("Failed to fetch notes");
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const handleAddOrUpdateNote = async (event) => {
    event.preventDefault();
    if (newNoteTitle.trim() && editorContent.trim()) {
      if (editingNoteId) {
        // Update existing note
        await updateNote(
          editingNoteId,
          newNoteTitle,
          editorContent,
          comment,
          subNotes
        );
      } else {
        // Create new note
        await createNote(newNoteTitle, editorContent, comment, subNotes);
      }
      resetForm();
    }
  };

  const createNote = async (title, content, comment, subNotes = []) => {
    try {
      const response = await axios.post(
        `${API_URL}/createNote`,
        { title, content, comment, subnotes: subNotes },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 201) {
        const { noteId } = response.data;
        setNotes((prevNotes) => [
          ...prevNotes,
          { id: noteId, title, content, comment, subnotes: subNotes },
        ]);
      } else {
        console.error("Failed to create note");
      }
    } catch (error) {
      console.error("Error creating note:", error);
    }
  };

  const updateNote = async (noteId, title, content, comment, subNotes = []) => {
    try {
      const response = await axios.put(
        `${API_URL}/updateNote/${noteId}`,
        { title, content, comment, subnotes: subNotes },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setNotes((prevNotes) =>
          prevNotes.map((note) =>
            note.id === noteId
              ? { ...note, title, content, comment, subnotes: subNotes }
              : note
          )
        );
      } else {
        console.error("Failed to update note");
      }
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  const deleteNote = async (noteId) => {
    try {
      const response = await axios.delete(`${API_URL}/deleteNote/${noteId}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
      } else {
        console.error("Failed to delete note");
      }
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const handleAddSubNote = () => {
    const newSubNote = prompt("Enter sub-note:");
    if (newSubNote) {
      setSubNotes([...subNotes, newSubNote]);
    }
  };

  const handleDeleteNote = (id) => {
    deleteNote(id);
  };

  const handleEditNote = (note) => {
    setNewNoteTitle(note.title);
    setEditorContent(note.content);
    setComment(note.comment);
    setSubNotes(note.subnotes || []);
    setEditingNoteId(note.id);
  };

  const resetForm = () => {
    setNewNoteTitle("");
    setEditorContent("");
    setComment("");
    setSubNotes([]);
    setEditingNoteId(null);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography
          variant="h4"
          style={{ fontFamily: "Poppins", color: "#FFFFFF" }}
        >
          Notes
        </Typography>

        <IconButton className={classes.iconButton} onClick={resetForm}>
          <AddNoteIcon />
        </IconButton>
      </Box>

      <Paper className={classes.paper}>
        <form onSubmit={handleAddOrUpdateNote}>
          <TextField
            label="Note Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newNoteTitle}
            onChange={(e) => setNewNoteTitle(e.target.value)}
            className={classes.noteTitleField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" type="submit">
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ backgroundColor: "#2D2D2D" }} // Dark background for input
          />
          <ReactQuill
            style={{ height: "200px", width: "100%", color: "#BDC4CE" }}
            value={editorContent}
            onChange={setEditorContent}
            theme="snow"
            placeholder="Write something..."
          />
          <Box>
            <TextField
              label="Add a Comment"
              variant="outlined"
              fullWidth
              margin="normal"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className={classes.commentField}
            />
            <Button
              className={classes.subNoteButton}
              onClick={handleAddSubNote}
            >
              Add Sub-note
            </Button>
          </Box>
          <Button className={classes.button} type="submit" variant="contained">
            SAVE
          </Button>
        </form>
      </Paper>

      

      <Box className={classes.notesContainer}>
      <Box className={classes.header}>
        <Typography
          variant="h4"
          style={{ fontFamily: "Poppins", color: "#FFFFFF" }}
        >
          Existing Notes
        </Typography>
      </Box>
        {notes.length > 0 ? (
          notes.map((note) => (
            <Paper key={note.id} className={classes.paper}>
              <Typography variant="h6" className={classes.noteTitle}>
                {note.title}
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => handleEditNote(note)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => handleDeleteNote(note.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Typography>
              <Divider
                style={{ margin: "16px 0", backgroundColor: "#BDC4CE" }}
              />
              <Typography
                variant="body2"
                className={classes.noteContent}
                dangerouslySetInnerHTML={{ __html: note.content }}
              />
              {note.image && (
                <img src={note.image} alt="Note" className={classes.image} />
              )}
              {note.comment && (
                <Typography variant="body2">Comment: {note.comment}</Typography>
              )}
              {note.subnotes && note.subnotes.length > 0 && (
                <List dense>
                  {note.subnotes.map((subNote, subIndex) => (
                    <ListItem key={subIndex}>
                      <ListItemText primary={subNote} />
                    </ListItem>
                  ))}
                </List>
              )}
              <Box display="flex" justifyContent="flex-end">
                <Button
                  className={classes.subNoteButton}
                  onClick={handleAddSubNote}
                >
                  Add Sub-Note
                </Button>
              </Box>
            </Paper>
          ))
        ) : (
          <Typography variant="body2" className={classes.noteContent}>
            No notes available.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Notes;
