import React, { useEffect, useRef } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  battle,
  craft1,
  craft2,
  explore,
  grab,
  sports,
  story,
} from "../../assets/images/index";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#000",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    width: "280px",
    borderRadius: "20px",
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    backgroundColor: "#0B0C14",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    margin: theme.spacing(1, 0),
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardTall: {
    height: "600px", // Taller card for staggered effect
  },
  cardShort: {
    height: "520px", // Shorter card for staggered effect
  },
  content: {
    padding: theme.spacing(2),
    zIndex: 1,
  },
  title: {
    fontSize: "1.5rem",
    color: "#fff",
    marginBottom: theme.spacing(1),
  },
  text: {
    color: "#b7b7b7",
    marginBottom: theme.spacing(2),
  },
  imageWrapper: {
    height: "auto",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    borderBottom: "2px solid #333",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    transition: "transform 8s",
  },
}));

const SmartphoneStyledCards = () => {
  const classes = useStyles();
  const card1ImageRef = useRef(null);
  const card3Image1Ref = useRef(null);
  const card3Image2Ref = useRef(null);

  let prevScrollY = window.scrollY;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollDirection = scrollY > prevScrollY ? "down" : "up"; // Determine scroll direction
      prevScrollY = scrollY;

      const translateValue = scrollY * 0.125; // Adjust translation speed

      // Card 1 image translation
      if (card1ImageRef.current) {
        if (scrollDirection === "down") {
          card1ImageRef.current.style.transform = `translateX(-${translateValue}px)`; // Translate right
        } else {
          card1ImageRef.current.style.transform = `translateX(${translateValue}px)`; // Translate left
        }
      }

      // Card 3 top image translation
      if (card3Image1Ref.current) {
        if (scrollDirection === "down") {
          card3Image1Ref.current.style.transform = `translateX(-${translateValue}px)`; // Translate right
        } else {
          card3Image1Ref.current.style.transform = `translateX(${translateValue}px)`; // Translate left
        }
      }

      // Card 3 bottom image translation (in opposite direction)
      if (card3Image2Ref.current) {
        if (scrollDirection === "down") {
          card3Image2Ref.current.style.transform = `translateX(${translateValue}px)`; // Translate left
        } else {
          card3Image2Ref.current.style.transform = `translateX(-${translateValue}px)`; // Translate right
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={classes.mainContainer}>
      <Grid container spacing={2} justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          className={classes.columnContainer}
          style={{ alignItems: "flex-end" }}
        >
          {/* Card 1 */}
          <Card className={`${classes.card} ${classes.cardTall}`}>
            <CardContent className={classes.content}>
              <Typography variant="h5" className={classes.title}>
              Grammar Mastery
              </Typography>
              <Typography className={classes.text}>
              Strengthen your foundation in any language with our comprehensive grammar tools.
              </Typography>
            </CardContent>
            <div className={classes.imageWrapper}>
              <img
                ref={card1ImageRef}
                src={battle}
                alt="Card Image 1"
                className={classes.image}
                style={{ height: "300px" }}
              />
            </div>
          </Card>

          {/* Card 2 */}
          <Card className={`${classes.card} ${classes.cardShort}`}>
            <CardContent className={classes.content}>
              <Typography variant="h5" className={classes.title}>
              Vocabulary Builder
              </Typography>
              <Typography className={classes.text}>
              Expand your word power with daily challenges and interactive lessons designed to boost retention
              </Typography>
            </CardContent>
            <div className={classes.imageWrapper}>
              <img src={grab} alt="Card Image 2" className={classes.image} />
            </div>
          </Card>

          {/* Card 3 */}
          <Card className={`${classes.card} ${classes.cardTall}`}>
            <CardContent className={classes.content}>
              <Typography variant="h5" className={classes.title}>
              IELTS Prep Pro
              </Typography>
              <Typography className={classes.text}>
              Ace your IELTS exam with personalized practice tests, tips, and real-world scenarios
              </Typography>
            </CardContent>
            <div className={classes.imageWrapper}>
              <img src={story} alt="Card Image 5" className={classes.image} />
            </div>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          className={classes.columnContainer}
          style={{ alignItems: "flex-start" }}
        >
          {/* Card 4 */}
          <Card className={`${classes.card} ${classes.cardShort}`}>
            <CardContent className={classes.content}>
              <Typography variant="h5" className={classes.title}>
              TOEFL Success Coach
              </Typography>
              <Typography className={classes.text}>
              Master TOEFL with expertly designed practice modules and essential tips for each section.
              </Typography>
            </CardContent>
            <div className={classes.imageWrapper}>
              <img src={explore} alt="Card Image 4" className={classes.image} />
            </div>
          </Card>

          {/* Card 5 */}
          <Card className={`${classes.card} ${classes.cardTall}`}>
            <CardContent className={classes.content}>
              <Typography variant="h5" className={classes.title}>
              Fluency in Action
              </Typography>
              <Typography className={classes.text}>
              Engage in real-life scenarios to enhance your speaking and listening skills effortlessly
              </Typography>
            </CardContent>
            <div className={classes.imageWrapper}>
              <img
                ref={card3Image1Ref}
                src={craft1}
                alt="Card Image 3 - Top"
                className={classes.image}
                style={{ height: "150px" }}
              />
            </div>
            <div className={classes.imageWrapper}>
              <img
                ref={card3Image2Ref}
                src={craft2}
                alt="Card Image 3 - Bottom"
                className={classes.image}
                style={{ height: "150px" }}
              />
            </div>
          </Card>

          {/* Card 6 */}
          <Card className={`${classes.card} ${classes.cardShort}`}>
            <CardContent className={classes.content}>
              <Typography variant="h5" className={classes.title}>
              Language Sprint
              </Typography>
              <Typography className={classes.text}>
              Challenge yourself with quick-fire language exercises for rapid learning on the go.
              </Typography>
            </CardContent>
            <div className={classes.imageWrapper}>
              <img src={sports} alt="Card Image 6" className={classes.image} />
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SmartphoneStyledCards;
