// First User Expirence Rrendered as a Dailog when succesfully logged in and continuing to the user DashBoard.

import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  LinearProgress,
  makeStyles,
  Card,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LanguageIcon from "@material-ui/icons/Language";
import SchoolIcon from "@material-ui/icons/School";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: theme.spacing(4),
    backgroundColor: "#ADD8E6", // Light blue background for dialog
    borderRadius: 8,
    height: "100vh",
    width: "100vh",
  },
  stepContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "200px",
    transition: "0.3s",
    margin: "0 auto",
    backgroundColor: "#FFFDD0",
    borderRadius: 20,
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  highlightedButton: {
    backgroundColor: "#23b5b5",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#1e8e8e",
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    backgroundColor: "#f1f1f1",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  finishButton: {
    backgroundColor: "#4caf50",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#388e3c",
    },
  },
}));

const steps = [
  "Language Selection",
  "Proficiency",
  "Flashcards",
  "Conversation",
];

const GetStartedUser = ({ open, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const generateRandomValue = () =>
    Math.round((Math.random() * (100 - 40) + 40) * 10) / 10;
  const [choice, setChoice] = useState({
    language: "",
    proficiency: "",
    flashCard: "",
    pronunciation: generateRandomValue(),
    understanding: generateRandomValue(),
    fluency: generateRandomValue(),
    grammar: generateRandomValue(),
    vocabulary: generateRandomValue(),
    filler: generateRandomValue(),
  });
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioURl] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const audioRef = useRef();

  const startRecording = async () => {
    setAudioChunks([]);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);

    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setAudioChunks((prev) => [...prev, event.data]);
      }
    };

    recorder.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURl(audioUrl);
    };

    recorder.start();
    setMediaRecorder(recorder);
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    setIsRecording(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChoice = (step, value) => {
    setChoice({ ...choice, [step]: value });
  };

  const handleSubmit = () => {
    let existingChoices = JSON.parse(localStorage.getItem("userChoices")) || [];
    existingChoices.push(choice);
    localStorage.setItem("userChoices", JSON.stringify(existingChoices));
    history.push("/dashboard"); // Replace with actual dashboard route
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className={classes.stepContent}>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Select a Language
            </Typography>
            <Button
              onClick={() => handleChoice("language", "English")}
              className={`${classes.button} ${
                choice.language === "English" ? classes.highlightedButton : ""
              }`}
              startIcon={<LanguageIcon />}
            >
              English
            </Button>
            <Button
              onClick={() => handleChoice("language", "Spanish")}
              className={`${classes.button} ${
                choice.language === "Spanish" ? classes.highlightedButton : ""
              }`}
              startIcon={<LanguageIcon />}
            >
              Spanish
            </Button>
            <Button
              onClick={() => handleChoice("language", "French")}
              className={`${classes.button} ${
                choice.language === "French" ? classes.highlightedButton : ""
              }`}
              startIcon={<LanguageIcon />}
            >
              French
            </Button>
          </div>
        );
      case 1:
        return (
          <div className={classes.stepContent}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Select Proficiency Level
            </Typography>
            <Button
              onClick={() => handleChoice("proficiency", "Beginner")}
              className={`${classes.button} ${
                choice.proficiency === "Beginner"
                  ? classes.highlightedButton
                  : ""
              }`}
              startIcon={<SchoolIcon />}
            >
              Beginner
            </Button>
            <Button
              onClick={() => handleChoice("proficiency", "Intermediate")}
              className={`${classes.button} ${
                choice.proficiency === "Intermediate"
                  ? classes.highlightedButton
                  : ""
              }`}
              startIcon={<SchoolIcon />}
            >
              Intermediate
            </Button>
            <Button
              onClick={() => handleChoice("proficiency", "Expert")}
              className={`${classes.button} ${
                choice.proficiency === "Expert" ? classes.highlightedButton : ""
              }`}
              startIcon={<SchoolIcon />}
            >
              Expert
            </Button>
          </div>
        );
      case 2:
        return (
          <div className={classes.stepContent}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Flashcards
            </Typography>
            <FlashOnIcon className={classes.icon} color="secondary" />
            <Typography>
              Select the correct option for the given word.
            </Typography>
            {/* Add flashcard content and buttons */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleChoice("flashCard", "option1")}
                  className={`${classes.button} ${
                    choice.flashCard === "option1"
                      ? classes.highlightedButton
                      : ""
                  }`}
                >
                  option1
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleChoice("flashCard", "option2")}
                  className={`${classes.button} ${
                    choice.flashCard === "option2"
                      ? classes.highlightedButton
                      : ""
                  }`}
                >
                  option2
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleChoice("flashCard", "option3")}
                  className={`${classes.button} ${
                    choice.flashCard === "option3"
                      ? classes.highlightedButton
                      : ""
                  }`}
                >
                  option3
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => handleChoice("flashCard", "option4")}
                  className={`${classes.button} ${
                    choice.flashCard === "option4"
                      ? classes.highlightedButton
                      : ""
                  }`}
                >
                  option4
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      case 3:
        return (
          <div className={classes.stepContent}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Record a Sentence
            </Typography>
            <Typography>
              Read and record a basic sentence to check your proficiency.
            </Typography>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isRecording ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={stopRecording}
                  startIcon={<StopIcon />}
                  className={classes.button}
                  style={{ color: "black" }}
                >
                  Stop Recording
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={startRecording}
                  startIcon={<MicIcon />}
                  className={classes.button}
                  style={{ color: "Red" }}
                >
                  Start Recording
                </Button>
              )}

              {audioUrl && (
                <div style={{ marginTop: "8px" }}>
                  <audio ref={audioRef} src={audioUrl} controls />
                </div>
              )}
            </div>
          </div>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="language-learning-journey"
      classes={{ paper: classes.dialogPaper }}
    >
      <LinearProgress
        variant="determinate"
        value={(activeStep / (steps.length - 1)) * 100}
      />
      <DialogContent>
        {renderStepContent(activeStep)}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={`${classes.button} ${classes.backButton}`}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              onClick={handleSubmit}
              className={`${classes.button} ${classes.finishButton}`}
              variant="contained"
            >
              Finish
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={`${classes.button} ${classes.highlightedButton}`}
            >
              Next
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GetStartedUser;
